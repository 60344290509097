import { AssignedVehiclesPanel, OwnedVehiclesPanel, VehiclesPanel } from "@/components/organisms";

export const Vehicles = () => {
  return (
    <div className="mb-12 flex flex-1 flex-col">
      <VehiclesPanel />
    </div>
  );
};

export const OwnedVehicles = () => {
  return <OwnedVehiclesPanel />;
};

export const AssignedVehicles = () => {
  return <AssignedVehiclesPanel />;
};

Vehicles.OwnedVehicles = OwnedVehicles;
Vehicles.AssignedVehicles = AssignedVehicles;
