import { zodResolver } from "@hookform/resolvers/zod";
import { isEmpty } from "ramda";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { CheckboxInput, DropdownSelect, Loading, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useOperator } from "@/hooks";
import { useLazyGetCountryStatesQuery } from "@/redux/apis/resource/resourceApi";
import { useAppSelector } from "@/redux/hooks";
import { countryStatesSelector } from "@/redux/slices/resource/selectors";
import { addToast, clsx, getCountryName } from "@/utils";
import { FilterDrawer } from "../common/FilterDrawer";
import { FilterPopover } from "../common/FilterPopover";
import { useVehicleFilters } from "../useVehicleFilters";

const schema = z.object({
  state: z.array(z.string()),
});

type FormData = z.infer<typeof schema>;

export const StateFilter = () => {
  const countryStates = useAppSelector(countryStatesSelector);
  const {
    filters: { state },
    setFilter,
  } = useVehicleFilters();

  const value = useMemo(() => {
    if (!state) return undefined;

    return countryStates
      .filter((countryState) => state.includes(countryState.id))
      .map((countryState) => countryState.name)
      .join(", ");
  }, [state, countryStates]);

  const onClose = () => setFilter("state", undefined);

  return (
    <FilterPopover name="state" value={value} label="State" onClose={onClose}>
      <StateFilterForm />
    </FilterPopover>
  );
};

interface StateFilterDrawerProps {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

export const StateFilterDrawer = ({ open, onOpenChange }: StateFilterDrawerProps) => {
  const countryStates = useAppSelector(countryStatesSelector);

  const {
    filters: { state },
    setFilter,
  } = useVehicleFilters();

  const value = useMemo(() => {
    if (!state) return undefined;

    return countryStates
      .filter((countryState) => countryState.id)
      .map((countryState) => countryState.name)
      .join(", ");
  }, [state, countryStates]);

  const onClose = () => setFilter("state", undefined);

  return (
    <FilterDrawer open={open} onOpenChange={onOpenChange} value={value} label="State" onClose={onClose}>
      <StateFilterForm />
    </FilterDrawer>
  );
};

const StateFilterForm = () => {
  const { bookableCountryList, country: defaultCountry } = useOperator();
  const [getCountryStates, { isFetching }] = useLazyGetCountryStatesQuery();
  const [country, setCountry] = useState(defaultCountry);
  const [states, setStates] = useState<Array<{ name: string; value: string }>>([]);
  const { setFilter, filters } = useVehicleFilters();

  const { control, setValue } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: { state: (filters.state as string[]) ?? [] },
  });

  useEffect(() => {
    getCountryStates(country)
      .unwrap()
      .then((data) => {
        const states = data.data.states;
        if (isEmpty(states)) addToast("danger", "No states found.");
        setStates(states.map((state) => ({ name: `${state.name} (${state.abbreviation})`, value: state.id })));
      })
      .catch((e) => {
        getErrorMessages(e).forEach((m) => {
          if (m === "Not found") addToast("danger", "No states found.");
          else addToast("danger", m);
        });
        setStates([]);
        setValue("state", []);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  const getCountryOptions = () => {
    const options: Array<{ name: string; value: string }> = [];
    bookableCountryList.forEach((countryCode) => {
      const country = getCountryName(countryCode);
      if (country) options.push({ name: country, value: countryCode });
    });

    return options;
  };

  const handleCheckAll = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target;

      if (checked) {
        setFilter(
          "state",
          states.map((state) => state.value)
        );
        setValue(
          "state",
          states.map((state) => state.value)
        );
      } else {
        setFilter("state", null);
        setValue("state", []);
      }
    },
    [setFilter, setValue, states]
  );

  const isAllStatesSelected = useMemo(() => {
    if (!filters.state) return false;
    return filters.state.length === states.length;
  }, [filters.state, states.length]);

  return (
    <div className="relative contents">
      {isFetching && <Loading className="rounded-lg" />}
      <Typography className="mb-4">State</Typography>
      {!isEmpty(bookableCountryList) && (
        <div className={clsx({ "mb-40": isEmpty(states) })}>
          <DropdownSelect
            className="mb-4 flex-1"
            options={getCountryOptions()}
            value={country}
            onChange={setCountry}
            placeholder="Select Country"
          />
          {!isEmpty(states) && <CheckboxInput label="All States" onChange={handleCheckAll} variant="check" checked={isAllStatesSelected} />}
        </div>
      )}
      <div className="mt-4 grid gap-4">
        <Controller
          control={control}
          name="state"
          render={({ field }) => (
            <>
              {states.map((countryState) => (
                <CheckboxInput
                  key={countryState.value}
                  label={countryState.name}
                  value={countryState.value}
                  onChange={(e) => {
                    if (e.target.checked) {
                      const newState = [...field.value, e.target.value];
                      field.onChange(newState);
                      setFilter("state", newState.length > 0 ? newState : null);
                    } else {
                      const newState = field.value.filter((i) => i !== e.target.value);
                      field.onChange(newState);
                      setFilter("state", newState.length > 0 ? newState : null);
                    }
                  }}
                  variant="check"
                  checked={field.value.includes(countryState.value)}
                />
              ))}
            </>
          )}
        />
      </div>
    </div>
  );
};
