import { createContext, PropsWithChildren, useContext } from "react";
import { useParams } from "react-router-dom";
import { Loading } from "@/components/atoms";
import { useOperator } from "@/hooks";
import { useGetDriverProfileQuery } from "@/redux/apis/driver/driverProfileApi";
import { DriverProfile } from "@/redux/slices/driver/types";

interface IVehiclesContext {
  driver: DriverProfile;
  isFetching: boolean;
}

export const VehiclesContext = createContext<IVehiclesContext | null>(null);

export const VehiclesProvider = ({ children }: PropsWithChildren) => {
  const { driverId } = useParams<{ driverId: string }>();
  const { id: operatorId } = useOperator();
  const { data: driver, isFetching } = useGetDriverProfileQuery({ id: driverId ?? "", operatorId }, { skip: !driverId });

  if (isFetching || !driver) return <Loading className="!bg-transparent" />;

  return <VehiclesContext.Provider value={{ driver: driver.data, isFetching }}>{children}</VehiclesContext.Provider>;
};

export const useVehiclesContext = () => {
  const context = useContext(VehiclesContext);
  if (!context) {
    throw new Error("useVehiclesContext must be used within an ManageDriverProvider");
  }
  return context;
};
