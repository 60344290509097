import { Controller, useFormContext } from "react-hook-form";
import { Divider, ErrorMessage, Icon, TextInput, Toggle, Tooltip, Typography } from "@/components/atoms";
import { clsx } from "@/utils";
import { DriverDetail } from "../../../../common/DriverDetail";
import { useProfileContext } from "../../context";
import { EditDriverDetailsFormData } from "../../form";

interface RegionAuDriverDetailsProps {
  editMode: boolean;
}

export const RegionAuDriverDetails = ({ editMode }: RegionAuDriverDetailsProps) => {
  const { driver } = useProfileContext();
  const { regionAu } = driver;
  const {
    control,
    register,
    formState: { errors: formErrors },
  } = useFormContext<EditDriverDetailsFormData>();

  if (!regionAu) return null;

  return (
    <>
      <Divider className="mt-6" />
      <div
        className={clsx(
          "mt-6 grid gap-6",
          editMode ? "grid-cols-1 md:grid-cols-2 2xl:grid-cols-3" : "grid-cols-1 md:grid-cols-3 2xl:grid-cols-5"
        )}
      >
        <DriverDetail
          editMode={editMode}
          label="ABN Registered for GST"
          detail={
            editMode ? (
              <Controller
                name="isGstRegistered"
                control={control}
                render={({ field }) => (
                  <div className="flex min-h-[46px] items-center">
                    <Toggle
                      className="flex-shrink-0"
                      checked={field.value}
                      onChange={(checked) => {
                        field.onChange(checked);
                      }}
                    />
                  </div>
                )}
              />
            ) : regionAu.isGstRegistered ? (
              "Yes"
            ) : (
              "No"
            )
          }
        />
        <DriverDetail
          editMode={editMode}
          label="ABN"
          detail={
            editMode ? (
              <div className="flex flex-col gap-1">
                <TextInput type="number" placeholder="00 000 000 000" maxLength={16} hasError={!!formErrors.abn} {...register("abn")} />
                <ErrorMessage errors={formErrors} name="abn" />
              </div>
            ) : (
              regionAu.abn
            )
          }
        />
        <DriverDetail
          editMode={editMode}
          label={
            <Typography variant="paragraph" className={clsx("truncate text-neutral-dark-gray", { "text-neutral-black": editMode })}>
              Medical Condition Affecting Driving Ability
              <Tooltip
                placement="top"
                maxWidth={467}
                content="Only complete if you have a medical condition affecting driving. Do not include wearing spectacles."
              >
                <span>
                  <Icon name="InfoCircle" variant="Bold" size="sm" className="ml-1.5 inline text-neutral-dark-gray" />
                </span>
              </Tooltip>
            </Typography>
          }
          detail={
            editMode ? (
              <div className="flex flex-col gap-1">
                <div className="flex flex-row items-center gap-2">
                  <Controller
                    name="hasMedicalCondition"
                    control={control}
                    render={({ field }) => (
                      <Toggle
                        className="flex-shrink-0"
                        checked={field.value}
                        onChange={(checked) => {
                          field.onChange(checked);
                        }}
                      />
                    )}
                  />
                  <TextInput
                    placeholder="Type here"
                    maxLength={1024}
                    hasError={!!formErrors.medicalConditionNotes}
                    {...register("medicalConditionNotes")}
                  />
                </div>
                <ErrorMessage errors={formErrors} name="medicalConditionNotes" />
              </div>
            ) : regionAu.hasMedicalCondition ? (
              `Yes${regionAu.medicalConditionNotes ? ` (${regionAu.medicalConditionNotes})` : ""}`
            ) : (
              "No"
            )
          }
        />
      </div>
    </>
  );
};
