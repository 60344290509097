import { CamelCaseKeys } from "camelcase-keys";
import { RawDriverProfile, RawFleetDriver } from "@/redux/apis/driver/types";
import { Page } from "@/redux/types";

export type DriverState = {
  fleet: FleetDriverState;
  offload: OffloadDriverState;
  payout: PayoutState;
};

export type FleetDriverState = {
  drivers: FleetDriver[];
  page: Page;
  options: FleetOptions;
};

export type FleetDriver = CamelCaseKeys<RawFleetDriver, true>;

export type FleetOptions = {
  isFleetLeader: Array<{ name: string; value: string }>;
  state: Array<{ name: string; value: string }>;
  accountStatus: Array<{ name: string; value: string }>;
  tier: Array<{ name: string; value: string }>;
  network: Array<{ name: string; value: string }>;
  vehicleBrand: Array<{ name: string; value: string }>;
  vehicleColor: Array<{ name: string; value: string }>;
  vehicleType: Array<{ name: string; value: string }>;
  year: { from: number; to: number };
};

export const fleetOptionsInitialState: FleetOptions = {
  isFleetLeader: [],
  state: [],
  accountStatus: [],
  tier: [],
  network: [],
  vehicleBrand: [],
  vehicleColor: [],
  vehicleType: [],
  year: { from: 0, to: 0 },
};

export type OffloadDriverState = {
  drivers: OffloadDriver[];
  page: Page;
  options: OffloadOptions;
};

export type OffloadDriver = {
  id: string;
  name: string;
  state: string;
  provider: {
    providerKey: string;
    id: string | null;
    name: string;
    phoneNumber: string;
    appDeepLink: string;
    assets: {
      logo: string;
      emblem: string;
    };
  };
  avatar: string | null;
  block: {
    isActive: boolean;
    reason: string;
  };
  tripCount: number;
  rating: number;
};

export type OffloadOptions = {
  state: Array<{ name: string; value: string }>;
  isBlocked: Array<{ name: string; value: string }>;
  vehicleBrand: Array<{ name: string; value: string }>;
  vehicleColor: Array<{ name: string; value: string }>;
  vehicleType: Array<{ name: string; value: string }>;
  year: { from: number; to: number };
};

export type DriverSearchItem = {
  id: string;
  name: string;
  avatar: string;
  email: string;
  phone: string;
  operator: {
    id: string;
    providerKey: string;
    name: string;
  };
  warnings: string[];
  errors: Array<{ key: string; value: string }>;
  recommendedCommissionRate?: number;
};

export const offloadOptionsInitialState: OffloadOptions = {
  state: [],
  isBlocked: [],
  vehicleBrand: [],
  vehicleColor: [],
  vehicleType: [],
  year: { from: 0, to: 0 },
};

export type DriverProfile = CamelCaseKeys<RawDriverProfile, true>;

export type DriverDocument = DriverProfile["documents"][0];

export type PayoutState = {
  payouts: Payout[];
  page: Page;
};

export type Payout = {
  id: string;
  amount: number;
  currency: string;
  status: string;
  statusReason: string;
  paidOn: string;
  updatedAt: string;
};
