import { Typography } from "@/components/atoms";
import { DriverDetail } from "../../../../common/DriverDetail";
import { useProfileContext } from "../../context";

export const PendingDriverAccountDetails = () => {
  const { driver } = useProfileContext();
  const { personal } = driver;

  return (
    <div className="mt-4 flex flex-col rounded-lg border border-neutral-gray bg-neutral-surface-gray p-5">
      <Typography variant="title" className="flex-1">
        Account Details
      </Typography>
      <div className="mt-6 grid grid-cols-1 gap-6 md:grid-cols-3 2xl:grid-cols-5">
        <DriverDetail label="Username / Login Email" detail={personal.email} />
      </div>
    </div>
  );
};
