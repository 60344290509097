import { useState } from "react";
import { ErrorPanel, Skeleton } from "@/components/atoms";
import { EmptyState } from "@/components/molecules";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useGetDriverVehiclesQuery } from "@/redux/apis/driver/driverProfileApi";
import { Vehicle } from "@/redux/slices/vehicle/types";
import { useVehiclesContext } from "../context";
import { AssignedVehicleTable } from "./AssignedVehiclesTable";
import { useGetAssignedVehiclesParams } from "./hooks";
import { SwitchAssignedVehicleModal } from "./modals/SwitchAssignedVehicleModal";

export const AssignedVehiclesPanel = () => {
  const { driver } = useVehiclesContext();
  const { params } = useGetAssignedVehiclesParams();
  const {
    data: response,
    isFetching,
    error,
  } = useGetDriverVehiclesQuery(
    {
      owner_uuid: driver.uuid,
      ...params,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const [pendingAssignedVehicle, setPendingAssignedVehicle] = useState<Vehicle | undefined>();

  if (error) return <ErrorPanel messages={getErrorMessages(error)} />;
  if (isFetching) return <Skeleton />;

  if (response && response.vehicles.length > 0)
    return (
      <>
        <AssignedVehicleTable driver={driver} items={response.vehicles ?? []} setPendingAssignedVehicle={setPendingAssignedVehicle} />
        <AssignedVehicleTable.Pagination page={response.page} />
        {pendingAssignedVehicle && (
          <SwitchAssignedVehicleModal
            driverId={driver.uuid}
            open={!!pendingAssignedVehicle}
            onClose={() => setPendingAssignedVehicle(undefined)}
            newVehicle={pendingAssignedVehicle}
            currentVehicle={driver.vehicle}
          />
        )}
      </>
    );

  return <EmptyState title="Assigned Vehicles" description="This driver is not assigned in any vehicle." />;
};
