import { useMemo } from "react";
import { usePagination, useSorting } from "@/hooks";
import { GetVehiclesParams } from "@/redux/apis/vehicle/types";

export const useGetAssignedVehiclesParams = () => {
  const { page, pageSize, clearPagination } = usePagination("assigned-vehicles", 30);
  const { sorting, setSorting, sortKey, direction } = useSorting();

  const params: GetVehiclesParams = useMemo(
    () => ({
      page,
      page_size: pageSize,
      sort: sortKey,
      direction,
    }),
    [page, pageSize, sortKey, direction]
  );

  return {
    params,
    sorting,
    setSorting,
    sortKey,
    direction,
    clearPagination,
  };
};
