import { useCallback, useMemo } from "react";
import { DataTable, Pagination } from "@/components/molecules";
import { useOperator, usePagination, useSorting } from "@/hooks";
import { DriverProfile } from "@/redux/slices/driver/types";
import { Vehicle } from "@/redux/slices/vehicle/types";
import { Page } from "@/redux/types";
import { assignedVehicleColumns } from "./fixtures";
import { generateAssignedVehiclesData } from "./helpers";

export interface AssignedVehicleTableProps {
  driver: DriverProfile;
  items: Vehicle[];
  setPendingAssignedVehicle: (vehicle: Vehicle) => void;
}

export const AssignedVehicleTable = ({ driver, items, setPendingAssignedVehicle }: AssignedVehicleTableProps) => {
  const { id: operatorId } = useOperator();
  const { sorting, setSorting } = useSorting();

  const onToggle = useCallback(
    (vehicle: Vehicle, assigned: boolean) => {
      if (assigned || !driver.vehicle) setPendingAssignedVehicle(vehicle);
    },
    [driver.vehicle, setPendingAssignedVehicle]
  );

  const data = useMemo(() => generateAssignedVehiclesData(operatorId, driver, items, onToggle), [driver, items, onToggle, operatorId]);

  return (
    <DataTable
      className="mt-4 w-full overflow-x-auto [&_table]:table-auto [&_tr]:bg-white"
      data={data}
      columns={assignedVehicleColumns}
      sorting={sorting}
      setSorting={setSorting}
    />
  );
};

const AssignedVehicleTablePagination = ({ page }: { page: Page }) => {
  const { current, size, total, count } = page;
  const { handlePageClick, handleSizeChange } = usePagination("assigned-vehicles", 30);

  return (
    <Pagination
      onPageClick={handlePageClick}
      onSizeChange={handleSizeChange}
      currentPage={current}
      pageSize={size}
      totalItems={total}
      pageCount={count}
    />
  );
};

AssignedVehicleTable.Pagination = AssignedVehicleTablePagination;
