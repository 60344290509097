import { useMemo } from "react";
import { DataTable, EmptyState } from "@/components/molecules";
import { useSorting } from "@/hooks";
import { VehicleCategory } from "@/redux/slices/vehicle/types";
import { generateExcludedCategoriesColumns, generateExcludedCategoriesTableData } from "./helpers";

export interface ExcludedCategoriesTableProps {
  items: VehicleCategory[];
  checkedIds: string[];
  onCheckAllChanged: (checked: boolean) => void;
  onCheckChanged: (vehicleId: string) => void;
}

export const ExcludedCategoriesTable = ({ items, checkedIds, onCheckChanged, onCheckAllChanged }: ExcludedCategoriesTableProps) => {
  const { sorting, setSorting } = useSorting();
  const allChecked = checkedIds.length > 0 && checkedIds.length === items.length;
  const columns = useMemo(() => generateExcludedCategoriesColumns(onCheckAllChanged, allChecked), [allChecked, onCheckAllChanged]);
  const data = useMemo(() => generateExcludedCategoriesTableData(items, checkedIds, onCheckChanged), [checkedIds, items, onCheckChanged]);
  return (
    <DataTable
      className="max-h-[400px] w-full overflow-auto [&_table]:table-auto [&_tr]:bg-white"
      data={data}
      columns={columns}
      sorting={sorting}
      setSorting={setSorting}
      emptyState={<EmptyState title="No Vehicle Category Found" description="Vehicle categories will show here" />}
    />
  );
};
