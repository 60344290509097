import { MyDriversPanel } from "@/components/organisms";
import { AddDriver } from "./subpages/AddDriver";
import { AdminActions } from "./subpages/AdminActions";
import { JobHistory } from "./subpages/JobHistory";
import { PayoutHistory } from "./subpages/PayoutHistory";
import { Profile } from "./subpages/Profile";
import { Vehicles } from "./subpages/Vehicles";

export const MyDrivers = () => {
  return (
    <div className="mb-12 flex flex-1 flex-col py-2">
      <MyDriversPanel />
    </div>
  );
};

MyDrivers.AddDriver = AddDriver;
MyDrivers.Profile = Profile;
MyDrivers.Vehicles = Vehicles;
MyDrivers.JobHistory = JobHistory;
MyDrivers.AdminActions = AdminActions;
MyDrivers.PayoutHistory = PayoutHistory;
