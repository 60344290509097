import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { TimeFrameInput, Toggle } from "@/components/atoms";
import { ConfigDetail } from "@/components/molecules";
import { OffloadingConfiguration } from "@/redux/slices/addOn/types";
import { clsx } from "@/utils";

interface OffloadingDetailsDispatchSettingsProps extends React.HTMLAttributes<HTMLDivElement> {
  configuration?: OffloadingConfiguration;
}

export const OffloadingEditDispatchSettings = ({ configuration, className, ...props }: OffloadingDetailsDispatchSettingsProps) => {
  const {
    control,
    trigger,
    watch,
    register,
    reset,
    formState: { errors },
  } = useFormContext<OffloadingConfiguration>();
  const { enabledAutomaticDispatchPrivateNetwork, enabledAutomaticDispatchPublicNetwork } = watch();

  useEffect(() => {
    reset(configuration);
  }, [configuration, reset]);

  return (
    <div className={clsx("flex flex-col pt-6", className)} {...props}>
      <ConfigDetail title="Auto Dispatch to Private Network" description="Set the time before pickup" hideDivider>
        <Controller
          name="enabledAutomaticDispatchPrivateNetwork"
          control={control}
          render={({ field }) => (
            <Toggle
              checked={field.value}
              onChange={(checked) => {
                field.onChange(checked);
                trigger();
              }}
            />
          )}
        />
      </ConfigDetail>
      <ConfigDetail title="" description="Set when the system will start dispatching before pickup" indentPadding="pl-4">
        <TimeFrameInput
          className="w-48"
          min="0"
          max="60"
          timeName="dispatchAutoPrivateNetworkTime"
          timeFrameName="dispatchAutoPrivateNetworkTimeframe"
          register={register}
          options={["hour", "minute", "day"]}
          disabled={!enabledAutomaticDispatchPrivateNetwork}
          error={errors.dispatchAutoPrivateNetworkTime?.message}
        />
      </ConfigDetail>
      <ConfigDetail title="Auto Dispatch to Public Network" description="Set the time before pickup">
        <Controller
          name="enabledAutomaticDispatchPublicNetwork"
          control={control}
          render={({ field }) => (
            <Toggle
              checked={field.value}
              onChange={(checked) => {
                field.onChange(checked);
                trigger();
              }}
            />
          )}
        />
      </ConfigDetail>
      <ConfigDetail title="" description="Set when the system will start dispatching before pickup" indentPadding="pl-4">
        <TimeFrameInput
          className="w-48"
          min="0"
          max="60"
          timeName="dispatchAutoPublicNetworkTime"
          timeFrameName="dispatchAutoPublicNetworkTimeframe"
          register={register}
          options={["hour", "minute", "day"]}
          disabled={!enabledAutomaticDispatchPublicNetwork}
          error={errors.dispatchAutoPublicNetworkTime?.message}
        />
      </ConfigDetail>
    </div>
  );
};
