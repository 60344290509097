import { format } from "date-fns";
import { parseISO } from "date-fns";
import { Divider, IconButton, Typography } from "@/components/atoms";
import { useProfile } from "@/hooks";
import { useLazyRequestDriverDocumentQuery } from "@/redux/apis/document/documentApi";
import { downloadFile } from "@/utils";
import { DriverDetail } from "../../../../common/DriverDetail";
import { useProfileContext } from "../../context";

export const PendingDriverDetails = () => {
  return (
    <div className="relative mt-4 flex flex-col rounded-lg border border-neutral-gray bg-neutral-surface-gray p-5">
      <div className="flex flex-row items-center">
        <Typography variant="title" className="flex-1">
          Driver Details
        </Typography>
      </div>
      <PersonalDetails />
      <LicenseDetails />
    </div>
  );
};

const PersonalDetails = () => {
  const { dateFormat } = useProfile();
  const { driver } = useProfileContext();
  const { personal } = driver;

  return (
    <div className="mt-6 grid grid-cols-1 flex-row gap-6 md:grid-cols-3 2xl:grid-cols-5">
      <DriverDetail label="First Name" detail={personal.firstName} />
      <DriverDetail label="Last Name" detail={personal.lastName} />
      <DriverDetail label="Contact Number" detail={personal.mobile} />
      <DriverDetail label="Date of Birth" detail={personal.dateOfBirth ? format(parseISO(personal.dateOfBirth), dateFormat) : "-"} />
      <DriverDetail label="Address" detail={personal.address} />
    </div>
  );
};

const LicenseDetails = () => {
  const { driver } = useProfileContext();
  const [requestDocument, { isLoading: isLoadingRequestDocument }] = useLazyRequestDriverDocumentQuery();

  const { licence, documents } = driver;
  const driverLicenseFront = documents.find((document) => document.type === "driver-licence");
  const driverLicenseBack = documents.find((document) => document.type === "driver-licence-back");

  const downloadDocument = (documentId: string) => {
    requestDocument({
      id: documentId,
      driverId: driver.uuid,
    })
      .unwrap()
      .then((res) => downloadFile(res.filename, documentId));
  };

  return (
    <>
      <Divider className="mt-6" />
      <div className="mt-6 grid grid-cols-1 gap-6 md:grid-cols-3 2xl:grid-cols-5">
        <DriverDetail
          label="Driver License No."
          detail={
            <div className="flex flex-col gap-2">
              <Typography variant="action">{licence.number || "-"}</Typography>
            </div>
          }
        />
        <DriverDetail label="Driver Registered In" detail={driver.licence.state.name} />
        <DriverDetail
          label="Driver License Photo (Front)"
          detail={
            driverLicenseFront ? (
              <div className="flex items-center gap-2">
                <Typography variant="action">{driverLicenseFront.filename}</Typography>
                <IconButton
                  disabled={isLoadingRequestDocument}
                  iconName="ImportCurve"
                  onClick={() => downloadDocument(driverLicenseFront.uuid)}
                />
              </div>
            ) : (
              "-"
            )
          }
        />
        <DriverDetail
          label="Driver License Photo (Back)"
          detail={
            driverLicenseBack ? (
              <div className="flex items-center gap-2">
                <Typography variant="action">{driverLicenseBack.filename}</Typography>
                <IconButton
                  disabled={isLoadingRequestDocument}
                  iconName="ImportCurve"
                  onClick={() => downloadDocument(driverLicenseBack.uuid)}
                />
              </div>
            ) : (
              "-"
            )
          }
        />
      </div>
    </>
  );
};
