import { useNavigate } from "react-router-dom";
import { Button, Loading, Panel, Typography } from "@/components/atoms";
import { PageHeader } from "../../../../PageHeader/PageHeader";
import { DriverNavigation } from "../../common/DriverNavigation";
import { PayoutHistoryProvider, usePayoutHistoryContext } from "./context";
import { PayoutHistoryModals } from "./PayoutHistoryModals";
import { PayoutDisclosure } from "./common/PayoutDisclosure";
import { useAppSelector } from "@/redux/hooks";
import { payoutsPageSelector, payoutsSelector } from "@/redux/slices/driver/selectors";
import { useGetPayoutsParams } from "./hooks/useGetPayoutsParams";
import { useGetPayoutsQuery } from "@/redux/apis/driver/driverPayoutApi";
import { EmptyState, Pagination } from "@/components/molecules";
import { usePagination } from "@/hooks";
import { isEmpty } from "ramda";
import { useEffect } from "react";

export const PayoutHistoryPanel = () => {
  const navigate = useNavigate();

  return (
    <PayoutHistoryProvider>
      <PageHeader.Actions onBack={() => navigate("../../")} />
      <Content />
    </PayoutHistoryProvider>
  );
};

const Content = () => {
  const { driver, setStep, setIsDisclosureOpen } = usePayoutHistoryContext();
  const { params } = useGetPayoutsParams(driver.uuid);
  const { isFetching } = useGetPayoutsQuery(params, { refetchOnMountOrArgChange: true });
  const payouts = useAppSelector(payoutsSelector);

  const { current, size, total, count } = useAppSelector(payoutsPageSelector);
  const { handlePageClick, handleSizeChange } = usePagination("payouts");

  useEffect(() => {
    if (isFetching) setIsDisclosureOpen(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  return (
    <div className="flex gap-3">
      <DriverNavigation driver={driver} />
      <Panel className="relative">
        {isFetching && <Loading className="rounded-lg" />}
        <div className="flex flex-col items-start sm:flex-row sm:items-center gap-6">
          <Typography variant="h3" className="flex-1">{`${driver.personal.firstName} ${driver.personal.lastName} Payout History`}</Typography>
          <Button variant="secondary" size="sm" onClick={() => setStep("bank-details")}>View Bank Details</Button>
        </div>
        <div className="flex flex-col gap-3 mt-5">
          {isEmpty(payouts) ? <EmptyState title="Payout History" description="There are currently no payout history available" /> : payouts.map(payout => (<PayoutDisclosure key={payout.id} payout={payout} />))}
        </div>
        <Pagination
          onPageClick={handlePageClick}
          onSizeChange={handleSizeChange}
          currentPage={current}
          pageSize={size}
          totalItems={total}
          pageCount={count}
        />
      </Panel>
      <PayoutHistoryModals />
    </div>
  );
};
