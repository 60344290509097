import { useMemo } from "react";
import { Skeleton } from "@/components/atoms";
import { DataTable } from "@/components/molecules";
import { useProfile } from "@/hooks";
import { clsx } from "@/utils";
import { usePayoutHistoryContext } from "../context";
import { useGetPayoutItemsQuery } from "@/redux/apis/driver/driverPayoutApi";
import { Payout } from "@/redux/slices/driver/types";
import { generatePayoutItemsTableColumns, generatePayoutItemsTableData } from "../helpers";

interface PayoutItemsTableProps extends React.HTMLAttributes<HTMLDivElement> {
  payout: Payout;
}

export const PayoutItemsTable = ({ payout, className, ...props }: PayoutItemsTableProps) => {
  const { driver } = usePayoutHistoryContext();
  const { dateFormat } = useProfile();
  const { data: queryData, isFetching } = useGetPayoutItemsQuery({ driverId: driver.uuid, payoutId: payout.id }, { refetchOnMountOrArgChange: true });
  const payoutItems = queryData?.data.items;
  const items = payoutItems ? payoutItems : [];

  const data = useMemo(
    () => generatePayoutItemsTableData(items, dateFormat, payout.currency),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items]
  );
  const columns = useMemo(() => generatePayoutItemsTableColumns(), []);

  if (isFetching) return <Skeleton />;

  return (
    <div className={clsx("relative flex", className)}>
      <DataTable className="[&_table]:table-auto overflow-x-auto w-full" columns={columns} data={data} {...props} />
    </div>
  );
};
