import { useMemo } from "react";
import { IconButton, Typography } from "@/components/atoms";
import { useLazyRequestDriverDocumentQuery } from "@/redux/apis/document/documentApi";
import { downloadFile } from "@/utils";
import { DriverDetail } from "../../../../common/DriverDetail";
import { useProfileContext } from "../../context";

export const PendingDriverApplicationDetails = () => {
  const {
    driver: { uuid, vehicle, regionAu, documents },
  } = useProfileContext();
  const [requestDocument, { isLoading }] = useLazyRequestDriverDocumentQuery();

  const downloadDocument = (documentId: string) => {
    requestDocument({
      id: documentId,
      driverId: uuid,
    })
      .unwrap()
      .then((res) => downloadFile(res.filename, documentId));
  };

  const accreditation = useMemo(() => {
    if (!documents) return undefined;
    return documents.find((doc) => doc.type === "accreditation");
  }, [documents]);

  const vehicleDetails = vehicle
    ? `${vehicle.model.make} ${vehicle.model.model}, ${vehicle.color}, Registration ${vehicle.registrationNumber}`
    : "-";

  return (
    <div className="relative mt-4 flex flex-col rounded-lg border border-neutral-gray bg-neutral-surface-gray p-5">
      <div className="flex flex-row items-center">
        <Typography variant="title" className="flex-1">
          Custom Application Answers
        </Typography>
      </div>
      <div className="mt-6 grid grid-cols-1 gap-6 md:grid-cols-3 2xl:grid-cols-5">
        <DriverDetail label="Vehicle Details" detail={vehicleDetails} />
        <DriverDetail label="ABN Registered for GST" detail={`${regionAu.isGstRegistered ? "Yes" : "No"}`} />
        <DriverDetail
          label="Accreditations"
          detail={
            accreditation ? (
              <div className="flex items-center gap-2">
                <Typography variant="action">{accreditation.filename}</Typography>
                <IconButton
                  className="!text-info"
                  iconName="ImportCurve"
                  variant="custom"
                  iconSize="lg"
                  disabled={isLoading}
                  onClick={() => downloadDocument(accreditation.uuid)}
                />
              </div>
            ) : (
              "-"
            )
          }
        />
      </div>
    </div>
  );
};
