import { Avatar, Typography } from "@/components/atoms";
import { getAssetUrl } from "@/utils";
import { useProfileContext } from "../../context";

export const PendingDriverHeader = () => {
  const { driver } = useProfileContext();
  const { personal } = driver;
  const driverName = `${personal.firstName} ${personal.lastName}`;

  const renderAvatar = () => {
    const src = getAssetUrl(driver.avatar || "");
    if (driver.isFleetLeader)
      return (
        <img
          className="h-[72px] w-[72px] shrink-0 rounded-full object-cover ring-4 ring-success ring-offset-4"
          src={src}
          alt={driverName}
        />
      );

    return <Avatar size="xl" src={src} alt={driverName} />;
  };

  return (
    <div className="flex flex-row items-center gap-6">
      {renderAvatar()}
      <div className="flex w-full flex-row">
        <div className="flex flex-1 flex-col gap-1">
          <Typography variant="h3">{driverName}</Typography>
        </div>
      </div>
    </div>
  );
};
