import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { z } from "zod";
import { Button, Dropzone, Icon, Loading, Modal, TextArea, TextInput, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import {
  useAddDriverIncidentDocumentMutation,
  useAddDriverIncidentMutation,
  useRemoveDriverIncidentDocumentMutation,
} from "@/redux/apis/driver/driverProfileApi";
import { addToast, clsx } from "@/utils";
import { useAdminActionsContext } from "../context";

interface AddIncidentReportModalProps {
  driverId: string;
  open: boolean;
  onClose: () => void;
}

const schema = z.object({
  documents: z
    .array(
      z.object({
        uuid: z.string(),
        filename: z.string(),
        status: z.string(),
      })
    )
    .optional(),
  jobId: z.string().optional(),
  report: z.string().min(1, "Please enter a valid incident report"),
});

type FormData = z.infer<typeof schema>;

export const AddIncidentReportModal = ({ driverId, open, onClose }: AddIncidentReportModalProps) => {
  const [addDriverIncident, { isLoading }] = useAddDriverIncidentMutation();

  const form = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  const handleClick = () => {
    addDriverIncident({
      driverId: driverId,
      documents: form.getValues("documents"),
      job_id: form.getValues("jobId"),
      text: form.getValues("report"),
    })
      .unwrap()
      .then(() => {
        addToast("success", "Successfully added incident report");
        onClose();
      });
  };

  return (
    <Modal open={open} onClose={onClose} className="w-[550px] !px-5 !py-8">
      <FormProvider {...form}>
        <div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
          <div className="flex flex-row items-center">
            <Typography variant="title" className="flex-1">
              Incident Report
            </Typography>
            <Icon name="close" isCustom className="flex cursor-pointer justify-end" onClick={onClose} />
          </div>
        </div>
        <UploadIncidentReportDocuments />
        <fieldset className="mt-4">
          <Typography variant="paragraph">Job ID (Optional)</Typography>
          <TextInput {...form.register("jobId")} placeholder="Enter Job ID" />
        </fieldset>
        <fieldset className="mt-4">
          <Typography variant="paragraph">Report</Typography>
          <TextArea {...form.register("report")} rows={4} placeholder="Incident Report" />
        </fieldset>
        <Button variant="primary" className="mt-4 w-full" size="lg" onClick={handleClick} disabled={isLoading}>
          Add Incident Report
        </Button>
      </FormProvider>
    </Modal>
  );
};

const UploadIncidentReportDocuments = () => {
  const {
    driver: { uuid: driverId },
  } = useAdminActionsContext();
  const { setValue, watch } = useFormContext<FormData>();
  const [addIncidentDocument, { isLoading }] = useAddDriverIncidentDocumentMutation();
  const [removeIncidentDocument, { isLoading: isDeleting }] = useRemoveDriverIncidentDocumentMutation();

  const documents = watch("documents");

  const onUpload = (file: File) => {
    const formData = new FormData();
    formData.append("file", file);

    addIncidentDocument({ driverId, body: formData })
      .unwrap()
      .then((res) => {
        addToast("success", "Document uploaded successfully");
        setValue("documents", documents ? [...documents, res] : [res]);
      })
      .catch((e) => {
        getErrorMessages(e).forEach((message) => addToast("danger", message));
      });
  };

  const handleDelete = (uuid: string) => {
    removeIncidentDocument({ driverId, documentId: uuid })
      .unwrap()
      .then(() => {
        addToast("success", "Document removed successfully");
        setValue(
          "documents",
          documents?.filter((doc) => doc.uuid !== uuid)
        );
      })
      .catch((e) => {
        getErrorMessages(e).forEach((message) => addToast("danger", message));
      });
  };

  return (
    <div className="mt-5">
      <Typography variant="paragraph" className="mb-2">
        Upload Incident Report Material
      </Typography>
      <Dropzone
        accept={{ "image/png": [".png"], "image/jpeg": [".jpeg", ".jpg"], "application/pdf": [".pdf"] }}
        maxSize={10000000}
        upload={onUpload}
        className={clsx("relative flex rounded-lg border border-dashed border-neutral-mid-gray bg-white text-neutral-dark-gray")}
        detail={
          <div className="flex w-full cursor-pointer flex-col place-content-center place-items-center gap-4 p-5">
            {isLoading && <Loading />}
            <Icon name="Folder2" variant="Bold" size={48} />
            <Typography variant="paragraph" className="text-center">
              Drag & Drop your files here or <span className="text-info">choose file</span>
              <br />
              500 MB max file size
            </Typography>
          </div>
        }
        disabled={isLoading || isDeleting}
      />

      <div className="mt-4 space-y-2">
        {documents?.map((document) => (
          <div key={document.uuid} className="flex items-center gap-2 rounded-lg bg-neutral-surface-gray px-3 py-2">
            <div className="grow-0">{renderIcon(document.filename.split(".").pop())}</div>

            <Typography variant="action" className="flex-1 truncate text-neutral-black">
              {document.filename}
            </Typography>
            <div className="flex gap-2.5">
              <Button
                disabled={isDeleting}
                startIcon="Trash"
                variant="custom"
                className="text-danger"
                onClick={() => handleDelete(document.uuid)}
              >
                Remove
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const renderIcon = (extension?: string) => {
  switch (extension) {
    case "pdf":
      return <Icon name="file-pdf" size="md" isCustom />;
    case "doc":
    case "docx":
      return <Icon name="google-doc" size="md" isCustom />;
    case "jpg":
    case "jpeg":
      return <Icon name="file-jpg" size="md" isCustom />;
    case "png":
      return <Icon name="file-png" size="md" isCustom />;
    default:
      return <Icon name="file" size="md" isCustom />;
  }
};
