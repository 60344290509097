import { useState } from "react";
import { Link } from "react-router-dom";
import { DropdownMenu, Icon, Loading, Toggle, Typography } from "@/components/atoms";
import { DataTableColumnDef } from "@/components/molecules";
import { FleetDriver } from "@/redux/slices/driver/types";
import { clsx, getAssetUrl } from "@/utils";
import { DriverStatusBadge } from "../common/DriverStatusBadge";
import { FleetLeaderModal } from "./modals/FleetLeaderModal";
import { ActiveStatuses, SuspendedStatuses } from "./types";
import { MyDriversTableData } from "./types";

export const generateMyDriversTableColumns = () => {
  const columns: DataTableColumnDef<MyDriversTableData>[] = [
    { accessorKey: "name", header: "Name", cell: (props) => props.row.original.name, id: "name", size: 300 },
    {
      accessorKey: "email",
      header: "Email",
      cell: (props) => props.row.original.email,
      id: "email",
      size: 370,
    },
    {
      accessorKey: "mobile",
      header: "Mobile",
      cell: (props) => props.row.original.mobile,
      enableSorting: false,
      size: 250,
    },
    {
      accessorKey: "rating",
      header: "Rating",
      cell: (props) => props.row.original.rating,
      id: "rating",
    },
    {
      accessorKey: "state",
      header: "State",
      cell: (props) => props.row.original.state,
      enableSorting: false,
    },
    {
      accessorKey: "tier",
      header: "Tier",
      cell: (props) => props.row.original.tier,
      id: "tier",
    },
    {
      accessorKey: "fleetLeader",
      header: FleetLeaderHeader,
      cell: (props) => props.row.original.fleetLeader,
      enableSorting: false,
    },
    {
      accessorKey: "status",
      header: "Status",
      cell: (props) => props.row.original.status,
      enableSorting: false,
      size: 250,
    },
    {
      accessorKey: "action",
      header: "Action",
      cell: (props) => props.row.original.action,
      size: 75,
      enableSorting: false,
    },
  ];

  return columns;
};

const FleetLeaderHeader = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div className="flex flex-row items-center gap-1.5">
        <Typography variant="small">Fleet Leader</Typography>
        <Icon isCustom name="help-circle" size="sm" className="cursor-pointer text-neutral-dark-gray" onClick={() => setShowModal(true)} />
      </div>
      <FleetLeaderModal open={showModal} onClose={() => setShowModal(false)} />
    </>
  );
};

export const generateMyDriversTableData = (
  items: FleetDriver[],
  toggleFleetLeader: (id: string, isFleetLeader: boolean) => void,
  isLoading: boolean
) => {
  const data = items.map(
    (i) =>
      ({
        name: renderDriverName(i),
        email: i.email,
        mobile: i.phone,
        rating: renderRating(i.metadata.rating),
        state: i.state ? i.state : "-",
        tier: i.tier || i.tier === 0 ? `${i.tier}` : "-",
        fleetLeader: (
          <div className="flex flex-row items-center gap-1.5">
            {i.isFleetLeader ? (
              <Icon name="TickCircle" variant="Bold" size="sm" className="text-success" />
            ) : (
              <Icon name="CloseCircle" variant="Bold" size="sm" className="text-neutral-dark-gray" />
            )}
            <Typography variant="paragraph">{i.isFleetLeader ? "Yes" : "No"}</Typography>
          </div>
        ),
        status: renderFleetStatus(i.status),
        action: renderMyDriverActions(i, isLoading, toggleFleetLeader),
      } as MyDriversTableData)
  );

  return data;
};

const renderMyDriverActions = (
  driver: FleetDriver,
  isLoadingToggleFleetLeader: boolean,
  toggleFleetLeader: (id: string, isFleetLeader: boolean) => void
) => {
  const isActiveDriver = [...ActiveStatuses, ...SuspendedStatuses].includes(driver.status);

  return (
    <DropdownMenu button={<Icon name="options" isCustom size="lg" />} position="bottom-start" className="!flex max-w-[24px]">
      <Link to={driver.uuid}>
        <DropdownMenu.Item>
          <Icon name="UserSquare" size="sm" className="mr-2.5" />
          <Typography variant="paragraph">Manage Driver</Typography>
        </DropdownMenu.Item>
      </Link>

      {isActiveDriver && (
        <DropdownMenu.Item className="relative" disabled>
          {isLoadingToggleFleetLeader && <Loading />}
          <Toggle
            className="mr-2.5"
            size="xs"
            checked={driver.isFleetLeader}
            onChange={(checked) => toggleFleetLeader(driver.uuid, checked)}
            disabled={isLoadingToggleFleetLeader}
          />
          <Typography variant="paragraph">Fleet Leader</Typography>
        </DropdownMenu.Item>
      )}
    </DropdownMenu>
  );
};

export const renderDriverName = (driver: FleetDriver) => {
  return (
    <Link to={driver.uuid}>
      <div className="flex flex-row items-center gap-2.5 hover:text-info">
        {renderFleetAvatar(driver.avatar, driver.name, driver.isFleetLeader)}
        <Typography variant="paragraph">{driver.name}</Typography>
        <DriverStatusBadge status={driver.status} />
      </div>
    </Link>
  );
};

export const renderFleetAvatar = (avatar: string | null, name: string, isFleetLeader: boolean) => (
  <div className="ml-1">
    {avatar ? (
      <div className={clsx("h-6 w-6 rounded-full", { "ring-2 ring-success ring-offset-2": isFleetLeader })}>
        <img className="rounded-full" src={getAssetUrl(avatar, { height: 24, width: 24, fit: "cover" })} alt={name} />
      </div>
    ) : (
      <div
        className={clsx("h-6 w-6 rounded-full bg-neutral-mid-gray", {
          "ring-2 ring-success ring-offset-2": isFleetLeader,
        })}
      />
    )}
  </div>
);

export const renderFleetStatus = (status: string) => {
  switch (status) {
    case "pending":
    case "Pending Qualification":
    case "Application in Progress":
    case "Pending Approval":
      return (
        <Typography variant="paragraph" className="flex flex-row items-center gap-2 capitalize">
          <span className="h-2 w-2 flex-shrink-0 rounded-full bg-neutral-dark-gray" /> {status}
        </Typography>
      );
    case "active":
    case "Approved":
      return (
        <Typography variant="paragraph" className="flex flex-row items-center gap-2 capitalize">
          <span className="h-2 w-2 flex-shrink-0 rounded-full bg-success" /> {status}
        </Typography>
      );
    case "Declined":
    case "removed":
    case "left":
    case "inactive":
    case "Permanently Terminated":
    case "Retired":
    case "suspended":
    case "Temporarily Suspended":
      return (
        <Typography variant="paragraph" className="flex flex-row items-center gap-2 capitalize">
          <span className="h-2 w-2 flex-shrink-0 rounded-full bg-danger" /> {status}
        </Typography>
      );
    default:
      return (
        <Typography variant="paragraph" className="flex flex-row items-center gap-2 capitalize">
          <span className="h-2 w-2 flex-shrink-0 rounded-full bg-neutral-dark-gray" /> {status}
        </Typography>
      );
  }
};

export const renderRating = (rating?: number | null) => (
  <div className="flex flex-row items-center gap-x-1.5">
    <Typography variant="paragraph">{rating || "-"}</Typography>
    {rating && <Icon name="Star1" className="fill-warning text-warning" />}
  </div>
);
