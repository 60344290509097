import { createContext, PropsWithChildren, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { Loading } from "@/components/atoms";
import { useOperator } from "@/hooks";
import { useGetDriverProfileQuery } from "@/redux/apis/driver/driverProfileApi";
import { DriverProfile, Payout } from "@/redux/slices/driver/types";
import { PayoutHistoryStep } from "./types";

interface IPayoutHistoryContext {
  driver: DriverProfile;
  isFetching: boolean;
  setStep: (step?: PayoutHistoryStep) => void;
  step?: PayoutHistoryStep;
  setSelectedPayout: (step?: Payout) => void;
  selectedPayout?: Payout;
  isDisclosureOpen: boolean;
  setIsDisclosureOpen: (step: boolean) => void;
}

export const PayoutHistoryContext = createContext<IPayoutHistoryContext | null>(null);

export const PayoutHistoryProvider = ({ children }: PropsWithChildren) => {
  const { driverId } = useParams<{ driverId: string }>();
  const { id: operatorId } = useOperator();
  const { data: driver, isFetching } = useGetDriverProfileQuery({ id: driverId ?? "", operatorId }, { skip: !driverId });
  const [step, setStep] = useState<PayoutHistoryStep>(undefined);
  const [selectedPayout, setSelectedPayout] = useState<Payout | undefined>(undefined);
  const [isDisclosureOpen, setIsDisclosureOpen] = useState(false);

  if (isFetching || !driver) return <Loading className="!bg-transparent" />;

  return <PayoutHistoryContext.Provider value={{ driver: driver.data, isFetching, step, setStep, selectedPayout, setSelectedPayout, isDisclosureOpen, setIsDisclosureOpen }}>{children}</PayoutHistoryContext.Provider>;
};

export const usePayoutHistoryContext = () => {
  const context = useContext(PayoutHistoryContext);
  if (!context) {
    throw new Error("usePayoutHistoryContext must be used within an PayoutHistoryProvider");
  }
  return context;
};
