import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Button, ErrorMessage, IconButton, TextInput, Typography } from "@/components/atoms";
import { clsx } from "@/utils";
import { DriverDetail } from "../../../../common/DriverDetail";
import { useProfileContext } from "../../context";
import { EditAccountDetailsFormData, editAccountDetailsFormSchema } from "../../form";

export const AccountDetails = () => {
  const { driver, setActiveModal, activeSection, setActiveSection } = useProfileContext();
  const { personal } = driver;

  const {
    register,
    handleSubmit,
    formState: { isDirty, errors: formErrors },
  } = useForm<EditAccountDetailsFormData>({
    resolver: zodResolver(editAccountDetailsFormSchema),
    defaultValues: {
      email: personal.email,
    },
  });

  const handleEdit = () => {
    if (activeSection !== undefined) setActiveModal("save-changes");
    else setActiveSection("account-details");
  };

  const onSubmit = handleSubmit(() => {
    setActiveSection(undefined);
    //todo:
    // if (editMode && isDirty) {
    //   const { contactName, contactNumber } = data;
    //   updateDriverProfile({
    //     id: driver.uuid,
    //     emergency_contact: {
    //       contact_name: contactName,
    //       contact_number: contactNumber,
    //     },
    //   })
    //     .unwrap()
    //     .then(() => {
    //       addToast("success", "Successfully updated account details");
    //       setActiveSection(undefined);
    //     })
    //     .catch((e) => getErrorMessages(e).forEach((message) => addToast("danger", message)));
    // }
  });

  const editMode = activeSection === "account-details";

  return (
    <form onSubmit={onSubmit} className="relative mt-4 flex flex-col rounded-lg border border-neutral-gray bg-neutral-surface-gray p-5">
      {/* {isLoading && <Loading />} */}
      <div className="flex flex-row items-center">
        <Typography variant="title" className="flex-1">
          Account Details
        </Typography>
        {editMode ? (
          <div className="flex flex-row items-center gap-3">
            <Button size="sm" variant="secondary" onClick={() => setActiveSection(undefined)}>
              Cancel
            </Button>
            <Button size="sm" variant="primary" type="submit" disabled={!isDirty}>
              Save
            </Button>
          </div>
        ) : (
          <IconButton variant="tertiary" iconName="Edit2" size="sm" onClick={handleEdit} />
        )}
      </div>

      <div
        className={clsx(
          "mt-6 grid gap-6",
          editMode ? "grid-cols-1 md:grid-cols-2 2xl:grid-cols-3" : "grid-cols-1 md:grid-cols-3 2xl:grid-cols-5"
        )}
      >
        <DriverDetail
          label="Username / Login Email"
          detail={
            editMode ? (
              <div className="flex flex-col gap-1">
                <TextInput hasError={!!formErrors.email} placeholder="Username / Login Email" {...register("email")} />
                <ErrorMessage errors={formErrors} name="email" />
              </div>
            ) : (
              personal.email
            )
          }
        />

        <DriverDetail
          className={editMode ? "hidden" : ""}
          label="Password"
          detail={
            <Typography variant="action" className="cursor-pointer text-info" onClick={() => setActiveModal("change-password")}>
              Change Password
            </Typography>
          }
        />
      </div>
    </form>
  );
};
