import { format } from "date-fns";
import { useBookingDates } from "@/components/organisms/Bookings/hooks/useBookingDates";
import { useSearch, useSorting } from "@/hooks";
import { GetDriverJobHistoryParams } from "@/redux/apis/driver/types";
import { useJobHistoryContext } from "./context";

export const useGetJobHistoryParams = () => {
  // const { page, pageSize } = usePagination("driver-jobs", 100);
  const { driver } = useJobHistoryContext();
  const { sorting, setSorting, sortKey, direction } = useSorting();
  const [search, setSearch] = useSearch();
  const [selectedDates, setSelectedDates] = useBookingDates();

  const params: GetDriverJobHistoryParams = {
    driverId: driver.uuid,
    status: [
      "awaiting_driver_allocation",
      "driver_allocated_not_confirmed",
      "driver_allocated_confirmed",
      "on_way_to_job",
      "commenced",
      "complete_awaiting_review",
      "closed",
      "cancelled",
    ],
    from_date: format(selectedDates.dateFrom, "yyyy-MM-dd"),
    to_date: format(selectedDates.dateTo, "yyyy-MM-dd"),
    job_id: isNaN(Number(search)) ? undefined : Number(search),
    direction,
    sort: sortKey,
  };

  return {
    params,
    selectedDates,
    setSelectedDates,
    setSearch,
    sorting,
    setSorting,
  };
};
