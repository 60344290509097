import { matchPath, Outlet, useLocation } from "react-router-dom";
import { Container } from "@/components/atoms";
import { Tabs } from "@/components/molecules";
import {
  ArchivedDriversActionMenu,
  DriversFilters,
  DriversFiltersProvider,
  MyDriversActionMenu,
  OffloadDriversActionMenu,
  PageHeader,
} from "@/components/organisms";
import { ArchivedDrivers } from "./ArchivedDrivers";
import { MyDrivers } from "./MyDrivers";
import { OffloadDrivers } from "./OffloadDrivers";

export const Drivers = () => {
  const { pathname } = useLocation();

  const pageTabs = [
    {
      name: "My Drivers",
      to: "my-drivers",
    },
    {
      name: "Offload Drivers",
      to: "offload",
    },
    {
      name: "Archived Drivers",
      to: "archived",
    },
  ];

  const isParentPage = Boolean(
    matchPath({ path: "/drivers/:slug" }, pathname) || matchPath({ path: "operator/:operatorId/drivers/:slug" }, pathname)
  );

  return (
    <DriversFiltersProvider>
      <Container className="w-full">
        <div className="sticky top-0 z-30 w-full bg-neutral-surface-gray">
          <PageHeader title="Drivers" skipCrumbs={1} />
          {isParentPage && (
            <div className="flex w-full gap-2 border-b !border-neutral-mid-gray py-1 max-xl:justify-between lg:py-0">
              <Tabs className="no-scrollbar min-w-0 border-none" tabs={pageTabs} />
              <ActionMenu />
            </div>
          )}
          <DriversFilters.ActiveFilters className="mt-2" />
        </div>
        <Outlet />
      </Container>
    </DriversFiltersProvider>
  );
};

const ActionMenu = () => {
  const { pathname } = useLocation();

  switch (true) {
    case pathname.includes("/drivers/my-drivers"):
      return <MyDriversActionMenu />;
    case pathname.includes("/drivers/offload"):
      return <OffloadDriversActionMenu />;
    case pathname.includes("/drivers/archived"):
      return <ArchivedDriversActionMenu />;
    default:
      return null;
  }
};

Drivers.MyDrivers = MyDrivers;
Drivers.OffloadDrivers = OffloadDrivers;
Drivers.ArchivedDrivers = ArchivedDrivers;
