import { DataTableColumnDef } from "@/components/molecules";
import { JobHistoryTableData } from "./types";

export const jobHistoryTableColumns: DataTableColumnDef<JobHistoryTableData>[] = [
  { accessorKey: "id", header: "ID", cell: (props) => props.row.original.bookingId, id: "ID", size: 150 },
  {
    accessorKey: "date",
    header: "Date",
    cell: (props) => props.row.original.date,
    id: "date",
    size: 150,
  },
  {
    accessorKey: "time",
    header: "Time",
    cell: (props) => props.row.original.time,
    size: 150,
  },
  {
    accessorKey: "operator",
    header: "Operator",
    cell: (props) => props.row.original.operator,
    enableSorting: false,
  },
  {
    accessorKey: "rating",
    header: "Rating",
    cell: (props) => props.row.original.rating,
    enableSorting: false,
  },
  {
    accessorKey: "status",
    header: "Status",
    cell: (props) => props.row.original.status,
    enableSorting: false,
  },
  {
    accessorKey: "action",
    header: "",
    cell: (props) => props.row.original.actions,
    enableSorting: false,
  },
];
