import { Panel } from "@/components/atoms";
import { DriverNavigation } from "../../../common/DriverNavigation";
import { PendingDriverAccountDetails } from "../PendingDriver/sections/PendingDriverAccountDetails";
import { PendingDriverApplicationDetails } from "../PendingDriver/sections/PendingDriverApplicationDetails";
import { PendingDriverDetails } from "../PendingDriver/sections/PendingDriverDetails";
import { PendingDriverHeader } from "../PendingDriver/sections/PendingDriverHeader";
import { useProfileContext } from "../context";
import { ArchivedDriverStatus } from "./sections/ArchivedDriverStatus";

export const ArchivedDriverPanel = () => {
  const { driver } = useProfileContext();

  return (
    <div className="flex gap-3">
      <DriverNavigation driver={driver} />
      <Panel>
        <PendingDriverHeader />
        <ArchivedDriverStatus />
        <PendingDriverApplicationDetails />
        <PendingDriverAccountDetails />
        <PendingDriverDetails />
      </Panel>
    </div>
  );
};
