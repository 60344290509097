import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Button, ErrorMessage, Icon, Loading, Modal, PasswordInput, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useUpdatePasswordMutation } from "@/redux/apis/account/accountApi";
import { addToast } from "@/utils";
import { useProfileContext } from "../context";
import { ChangePasswordFormData, changePasswordFormSchema } from "../form";

interface ChangePasswordModalProps {
  open: boolean;
  onClose: () => void;
}

export const ChangePasswordModal = ({ open, onClose }: ChangePasswordModalProps) => {
  const { driver } = useProfileContext();
  const [updatePassword, { isLoading }] = useUpdatePasswordMutation();

  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
  } = useForm<ChangePasswordFormData>({
    resolver: zodResolver(changePasswordFormSchema),
  });

  const handleOnClose = () => {
    if (!isLoading) onClose();
  };

  const onSubmit = handleSubmit((data) => {
    updatePassword({ id: driver.uuid, password: data.newPassword })
      .unwrap()
      .then(() => {
        addToast("success", "Successfully changed password");
        onClose();
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  });

  const renderHeader = () => (
    <div className="sm:flex sm:items-start">
      <div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
        <div className="flex flex-row items-center">
          <Typography variant="title" className="flex-1">
            Change Password
          </Typography>
          <Icon name="close" isCustom className="flex cursor-pointer justify-end" onClick={handleOnClose} />
        </div>
      </div>
    </div>
  );

  return (
    <Modal open={open} onClose={handleOnClose} className="w-[80vw] md:w-[514px] lg:w-[514px]">
      {isLoading && <Loading />}
      {renderHeader()}
      <form onSubmit={onSubmit} className="mt-3">
        <Typography variant="paragraph" className="text-neutral-dark-gray">
          As an operator, you can update the driver&apos;s password. The driver will need to use the new password to log in moving forward.
        </Typography>
        <div className="mt-6 gap-1">
          <Typography variant="paragraph">Enter new password</Typography>
          <PasswordInput required placeholder="Enter new password" hasError={!!formErrors.newPassword} {...register("newPassword")} />
          <ErrorMessage errors={formErrors} name="newPassword" />
          <Typography variant="small" className="text-neutral-dark-gray">
            Note: minimum of 6 characters
          </Typography>
        </div>
        <div className="mt-4 gap-1">
          <Typography variant="paragraph">Confirm new password</Typography>
          <PasswordInput required placeholder="Enter password" hasError={!!formErrors.confirmPassword} {...register("confirmPassword")} />
          <ErrorMessage errors={formErrors} name="confirmPassword" />
        </div>
        <Button type="submit" size="lg" variant="primary" className="mt-8 w-full">
          Change Password
        </Button>
      </form>
    </Modal>
  );
};
