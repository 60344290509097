import { usePayoutHistoryContext } from "./context";
import { BankDetailsModal } from "./modals/BankDetailsModal";
import { MarkAsFailedModal } from "./modals/MarkAsFailedModal";

export const PayoutHistoryModals = () => {
  const { step, setStep } = usePayoutHistoryContext();
  const onClose = () => setStep(undefined);

  return (
    <>
      <BankDetailsModal open={step === "bank-details"} onClose={onClose} />
      <MarkAsFailedModal open={step === "mark-as-failed"} onClose={onClose} />
    </>
  );
};
