import { DisclosurePanel } from "@/components/molecules";
import { TransferTypeExcludedCategories } from "../ExcludedCategories/TransferTypeExcludedCategories";
import { TransferTypeIsAdminOnlyField } from "./fields/TransferTypeIsAdminOnlyField";
import { TransferTypeIsDispatchEnabledField } from "./fields/TransferTypeIsDispatchEnabledField";
import { TransferTypeIsSpecialEventField } from "./fields/TransferTypeIsSpecialEventField";
import { TransferTypeIsTaxExemptField } from "./fields/TransferTypeIsTaxExemptField";

export const TransferTypeAdvancedSettings = () => {
  return (
    <DisclosurePanel title="Advanced Settings" className="col-span-2 flex flex-col">
      <div className="flex w-full flex-col space-y-6">
        <TransferTypeExcludedCategories />
        <TransferTypeIsAdminOnlyField />
        <TransferTypeIsDispatchEnabledField />
        <TransferTypeIsTaxExemptField />
        <TransferTypeIsSpecialEventField />
      </div>
    </DisclosurePanel>
  );
};
