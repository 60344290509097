import { useEffect } from "react";
import { BooleanDisplay, Button, Divider, Loading, Typography } from "@/components/atoms";
import { useLazyGetOffloadingConfigurationQuery } from "@/redux/apis/addOn/addOnApi";
import { OffloadingConfiguration } from "@/redux/slices/addOn/types";
import { renderDispatchTime } from "../../DispatchSettings/helpers";

interface OffloadingDetailsProps {
  onEdit: () => void;
  configuration?: OffloadingConfiguration;
}

export const OffloadingDetails = ({ onEdit, configuration }: OffloadingDetailsProps) => {
  const [getOffloadingConfiguration, { isFetching }] = useLazyGetOffloadingConfigurationQuery();

  useEffect(() => {
    getOffloadingConfiguration();
  }, [getOffloadingConfiguration]);

  return (
    <div className="relative my-4 flex w-full flex-col rounded-lg bg-neutral-surface-gray p-5">
      {isFetching && <Loading className="rounded-lg" />}
      <div className="flex flex-row items-center">
        <div className="flex flex-1 flex-col">
          <Typography variant="action">Auto Dispatch to Private Network</Typography>
          <Typography variant="paragraph" className="text-neutral-dark-gray">
            Set the time before pickup
          </Typography>
        </div>
        <BooleanDisplay status={configuration?.enabledAutomaticDispatchPrivateNetwork}>
          <Typography variant="paragraph">{configuration?.enabledAutomaticDispatchPrivateNetwork ? "Yes" : "No"}</Typography>
        </BooleanDisplay>
      </div>
      <div className="mt-5 flex flex-row items-center">
        <div className="flex flex-1 flex-row items-center gap-3">
          <Typography variant="action" className="text-neutral-dark-gray">
            Set when the system will start dispatching before pickup
          </Typography>
        </div>
        <Typography variant="paragraph">
          {renderDispatchTime(configuration?.dispatchAutoPrivateNetworkTime, configuration?.dispatchAutoPrivateNetworkTimeframe)}
        </Typography>
      </div>
      <Divider className="my-6" />
      <div className="flex flex-row items-center">
        <div className="flex flex-1 flex-col">
          <Typography variant="action">Auto Dispatch to Public Network</Typography>
          <Typography variant="paragraph" className="text-neutral-dark-gray">
            Set the time before pickup
          </Typography>
        </div>
        <BooleanDisplay status={configuration?.enabledAutomaticDispatchPublicNetwork}>
          <Typography variant="paragraph">{configuration?.enabledAutomaticDispatchPublicNetwork ? "Yes" : "No"}</Typography>
        </BooleanDisplay>
      </div>
      <div className="mt-5 flex flex-row items-center">
        <div className="flex flex-1 flex-row items-center gap-3">
          <Typography variant="action" className="text-neutral-dark-gray">
            Set when the system will start dispatching before pickup
          </Typography>
        </div>
        <Typography variant="paragraph">
          {renderDispatchTime(configuration?.dispatchAutoPublicNetworkTime, configuration?.dispatchAutoPublicNetworkTimeframe)}
        </Typography>
      </div>
      <div className="mt-8 flex w-full flex-row items-center justify-end">
        <Button variant="primary" size="sm" onClick={onEdit}>
          Edit Settings
        </Button>
      </div>
    </div>
  );
};
