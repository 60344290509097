import { Navigate } from "react-router-dom";
import {
  Accreditations,
  Bookings,
  Drivers,
  Clients,
  Companies,
  Payments,
  PrivateNetwork,
  Profile,
  RatingManager,
  TripReporting,
  ExternalSuppliers,
  Onboarding,
  Vehicles,
  Suspended,
  AddOns,
} from "@/components/pages";
import { ConditionalAccess } from "./ConditionalAccess.tsx/ConditionalAccess";
import { RequireAccess } from "./RequireAccess/RequireAccess";
import { passengerRoutes } from "./passenger";

export const operatorRoutes = [
  {
    path: "accreditations",
    element: <RequireAccess action="manage" subject="providerAccreditations" />,
    handle: { pageName: "Accreditations" },
    children: [
      {
        index: true,
        element: <Accreditations />,
      },
      {
        path: ":id/history",
        handle: { pageName: "History Logs" },
        element: <Accreditations.History />,
      },
    ],
  },
  {
    path: "rating-manager",
    element: <RequireAccess action="manage" subject="providerRatingDriver" />,
    handle: { pageName: "Rating Manager" },
    children: [
      {
        index: true,
        element: <RatingManager />,
      },
    ],
  },
  {
    path: "drivers",
    element: <RequireAccess action="manage" subject="providerDriver" />,
    handle: { pageName: "Drivers" },
    children: [
      {
        path: "*",
        element: <Drivers />,
        children: [
          {
            index: true,
            element: <Navigate to="my-drivers" replace />,
          },
          {
            path: "my-drivers",
            children: [
              {
                index: true,
                element: <Drivers.MyDrivers />,
              },
              {
                path: "add",
                element: <Drivers.MyDrivers.AddDriver />,
              },
              {
                path: ":driverId",
                children: [
                  {
                    index: true,
                    element: <Navigate to="profile" replace />,
                  },
                  {
                    path: "profile",
                    element: <Drivers.MyDrivers.Profile />,
                  },
                  {
                    path: "vehicles",
                    element: <Drivers.MyDrivers.Vehicles />,
                    children: [
                      {
                        index: true,
                        element: <Navigate to="assigned" />,
                      },
                      {
                        path: "owned",
                        element: <Drivers.MyDrivers.Vehicles.OwnedVehicles />,
                      },
                      {
                        path: "assigned",
                        element: <Drivers.MyDrivers.Vehicles.AssignedVehicles />,
                      },
                    ],
                  },
                  {
                    path: "job-history",
                    element: <Drivers.MyDrivers.JobHistory />,
                  },
                  {
                    path: "payout-history",
                    element: <Drivers.MyDrivers.PayoutHistory />,
                  },
                  {
                    path: "admin",
                    element: <Drivers.MyDrivers.AdminActions />,
                  },
                ],
              },
            ],
          },
          {
            path: "offload",
            children: [
              {
                index: true,
                element: <Drivers.OffloadDrivers />,
              },
            ],
          },
          {
            path: "archived",
            children: [
              {
                index: true,
                element: <Drivers.ArchivedDrivers />,
              },
              {
                path: ":driverId",
                children: [
                  {
                    index: true,
                    element: <Navigate to="profile" replace />,
                  },
                  {
                    path: "profile",
                    element: <Drivers.ArchivedDrivers.Profile />,
                  },
                  {
                    path: "job-history",
                    element: <Drivers.ArchivedDrivers.JobHistory />,
                  },
                  {
                    path: "admin",
                    element: <Drivers.ArchivedDrivers.AdminActions />,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "external-suppliers",
    element: <RequireAccess action="manage" subject="providerExternalSupplier" />,
    handle: { pageName: "External Suppliers" },
    children: [
      {
        index: true,
        element: <ExternalSuppliers />,
      },
      {
        path: "add",
        handle: { pageName: "Add External Supplier" },
        element: <ExternalSuppliers.Add />,
      },
    ],
  },
  {
    path: "clients",
    element: <RequireAccess action="manage" subject="providerClient" />,
    handle: { pageName: "Clients" },
    children: [
      {
        index: true,
        element: <Clients />,
      },
      {
        path: "add",
        handle: { pageName: "Add Client" },
        element: <Clients.Add />,
      },
    ],
  },
  {
    path: "companies",
    handle: { pageName: "Companies" },
    element: <RequireAccess action="view" subject="providerCompany" />,
    children: [
      {
        index: true,
        element: <Companies />,
      },
      {
        path: ":id",
        children: [
          {
            index: true,
            handle: { pageKey: "companyName" },
            element: <Companies.View />,
          },
        ],
      },
      {
        path: ":id/*",
        element: <RequireAccess action="manage" subject="providerCompany" />,
        children: [
          {
            path: "edit",
            handle: { pageName: "Update Company" },
            element: <Companies.Edit />,
          },
        ],
      },
      {
        path: "add",
        element: <RequireAccess action="manage" subject="providerCompany" />,
        handle: { pageName: "Add New Company" },
        children: [
          {
            index: true,
            element: <Companies.Add />,
          },
        ],
      },
    ],
  },
  {
    path: "payments",
    element: <RequireAccess action="view" subject="providerPayments" />,
    handle: { pageName: "Payments" },
    children: [
      {
        path: "*",
        element: <Payments />,
        children: [
          {
            index: true,
            element: (
              <ConditionalAccess
                action="view"
                subject="providerPayments"
                truthyPath="credit-card-payments"
                falsyPath="payment-configuration"
              />
            ),
          },
          {
            path: "invoicing",
            element: <Payments.Invoicing />,
            children: [
              {
                index: true,
                element: <Navigate to="uninvoiced-jobs" />,
              },
              {
                path: "uninvoiced-jobs",
                element: <Payments.Invoicing.UninvoicedJobs />,
              },
              {
                path: "invoices",
                element: <Payments.Invoicing.Invoices />,
              },
            ],
          },
          {
            path: "credit-card-payments",
            element: <RequireAccess action="view" subject="providerPayments" />,
            children: [
              {
                index: true,
                element: <Payments.CreditCardPayments />,
              },
            ],
          },
          {
            path: "payout-history",
            element: <RequireAccess action="view" subject="providerPayments" />,
            children: [
              {
                index: true,
                element: <Payments.PayoutHistory />,
              },
              {
                path: ":id",
                handle: {
                  pageKey: "arrivalDate",
                },
                element: <Payments.PayoutHistory.Payout />,
              },
            ],
          },
          {
            path: "debit-history",
            element: <RequireAccess action="view" subject="providerPayments" />,
            children: [
              {
                index: true,
                element: <Payments.DebitHistory />,
              },
              {
                path: ":id",
                handle: {
                  pageKey: "creationDate",
                },
                element: <Payments.DebitHistory.DebitRecords />,
              },
            ],
          },
          {
            path: "payment-configuration",
            children: [
              {
                index: true,
                element: <Payments.PaymentConfiguration />,
              },
              {
                path: ":type?/:mode?",
                element: <Payments.PaymentConfiguration />,
              },
              {
                path: "debit/history",
                element: <Payments.PaymentConfiguration.DebitsHistory />,
                handle: { pageName: "Bank Details - Debits" },
              },
              {
                path: "deposit/history",
                element: <Payments.PaymentConfiguration.DepositsHistory />,
                handle: { pageName: "Bank Details - Deposits" },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "trip-reporting",
    element: <RequireAccess action="view" subject="providerReportingTrip" />,
    handle: { pageName: "Trip Reporting" },
    children: [
      {
        index: true,
        element: <TripReporting />,
      },
    ],
  },
  {
    path: "private-network",
    element: <RequireAccess action="view" subject="providerPrivateNetwork" />,
    handle: { pageName: "Private Network" },
    children: [
      {
        index: true,
        element: <PrivateNetwork />,
      },
      {
        path: ":id/history",
        handle: { pageName: "History Logs" },
        element: <PrivateNetwork.History />,
      },
      {
        path: ":id/fleet-leaders/:networkOperatorId",
        handle: { pageName: "Fleet Leaders" },
        element: <PrivateNetwork.FleetLeaders />,
      },
    ],
  },
  {
    path: "profile",
    handle: { pageName: "Profile" },
    children: [
      {
        index: true,
        element: <Profile />,
      },
      {
        path: "edit",
        element: <Profile.Edit />,
      },
      {
        path: "change-password",
        element: <Profile.ChangePassword />,
      },
      {
        path: "secure-account",
        element: <Profile.SecureAccount />,
      },
      {
        path: "verify-email",
        element: <Profile.VerifyEmail />,
      },
      {
        path: "verify-phone",
        element: <Profile.VerifyPhone />,
      },
      {
        path: "signup-success",
        element: <Profile.SignUpSuccess />,
      },
      {
        path: "payment-method-setup",
        element: <Profile.PaymentMethodSetup />,
      },
    ],
  },
  {
    path: "bookings",
    element: <RequireAccess action="manage" subject="providerClient" />,
    handle: { pageName: "Bookings" },
    children: [
      {
        index: true,
        element: <Bookings />,
      },
    ],
  },
  {
    path: "onboarding",
    handle: { pageName: "Onboarding" },
    children: [
      {
        index: true,
        element: <Onboarding />,
      },
    ],
  },
  {
    path: "vehicles",
    element: <RequireAccess action="manage" subject="providerConfigVehicle" />,
    handle: { pageName: "Vehicles" },
    children: [
      {
        path: "*",
        element: <Vehicles />,
        children: [
          {
            index: true,
            element: <Navigate to="my-vehicles" replace />,
          },
          {
            path: "my-vehicles",
            handle: { pageName: "My Vehicles" },
            children: [
              {
                index: true,
                element: <Vehicles.MyVehicles />,
              },
              {
                path: "add",
                handle: { pageName: "Add Vehicle" },
                element: <Vehicles.MyVehicles.AddVehicle />,
              },
              {
                path: ":vehicleId",
                element: <Vehicles.MyVehicles.ViewVehicle />,
              },
              {
                path: ":vehicleId/edit",
                handle: { pageName: "Edit Vehicle" },
                element: <Vehicles.MyVehicles.EditVehicle />,
              },
            ],
          },
          {
            path: "affiliate-vehicles",
            element: <Vehicles.AffiliateVehicles />,
          },
          {
            path: "archived-vehicles",
            handle: { pageName: "Archived Vehicles" },
            children: [
              {
                index: true,
                element: <Vehicles.ArchivedVehicles />,
              },
              {
                path: ":vehicleId",
                element: <Vehicles.ArchivedVehicles.ViewVehicle />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "onboarding",
    handle: { pageName: "Onboarding" },
    children: [
      {
        index: true,
        element: <Onboarding />,
      },
    ],
  },
  {
    path: "optimizations",
    handle: { pageName: "Optimizations" },
    children: [
      {
        index: true,
        element: <Onboarding />,
      },
    ],
  },
  {
    path: "suspended",
    handle: { pageName: "Suspended" },
    children: [
      {
        index: true,
        element: <Suspended />,
      },
    ],
  },
  {
    path: "add-ons",
    handle: { pageName: "Add Ons" },
    element: <AddOns />,
  },
  {
    path: "passenger/:passengerId",
    children: [
      {
        index: true,
        element: <Navigate to="my-bookings" replace />,
      },
      ...passengerRoutes,
    ],
  },
  ...passengerRoutes,
];
