import { Button, Typography } from "@/components/atoms";
import { BankAccount } from "@/redux/apis/bankAccount/types";
import { BankDetailsStep } from "../types";

interface BankDetailsViewProps extends React.HTMLAttributes<HTMLDivElement> {
  setStep: (step: BankDetailsStep) => void;
  bankAccount?: BankAccount;
}

export const BankDetailsView = ({ setStep, bankAccount, className, ...props }: BankDetailsViewProps) => {
  const bankAccountDetails = bankAccount?.auBankAccount;

  return (
    <div className={className} {...props}>
      <div className="grid grid-cols-2 gap-4 bg-neutral-surface-gray rounded-lg mt-3 p-5">
        <div className="col-span-2 sm:col-span-1 flex flex-col">
          <Typography variant="paragraph" className="text-neutral-dark-gray">Full Name</Typography>
          <Typography variant="paragraph">{bankAccountDetails?.accountHolder ? bankAccountDetails.accountHolder : "-"}</Typography>
        </div>
        <div className="col-span-2 sm:col-span-1 flex flex-col">
          <Typography variant="paragraph" className="text-neutral-dark-gray">Account number</Typography>
          <Typography variant="paragraph">{bankAccountDetails?.accountNumber ? bankAccountDetails.accountNumber : "-"}</Typography>
        </div>
        <div className="col-span-2 sm:col-span-1 flex flex-col">
          <Typography variant="paragraph" className="text-neutral-dark-gray">Bank State Branch</Typography>
          <Typography variant="paragraph">{bankAccountDetails?.bsb ? bankAccountDetails.bsb : "-"}</Typography>
        </div>
      </div>
      <div className="flex flex-row justify-end mt-4">
        <Button variant="secondary" size="sm" startIcon="Edit2" className="w-full sm:w-fit" onClick={() => setStep("edit")}>Update Bank Details</Button>
      </div>
    </div>
  );
};