import { Button, ErrorMessage, Loading, TextInput, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useUpdateBankAccountMutation } from "@/redux/apis/bankAccount/bankAccountApi";
import { BankAccount } from "@/redux/apis/bankAccount/types";
import { addToast } from "@/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { usePayoutHistoryContext } from "../context";
import { BankDetailsStep } from "../types";

interface BankDetailsEditProps {
  setStep: (step: BankDetailsStep) => void;
  bankAccount?: BankAccount;
}

const schema = z.object({
  accountHolder: z.string().min(1, "Field is required and can't be empty.").max(100, "Maximum of 100 characters."),
  accountNumber: z.string().min(1, "Field is required and can't be empty.").max(9, "Maximum of 9 characters"),
  bsb: z.string().regex(/^\d{3}-?\d{3}$/, "Invalid BSB"),
});

type BankDetailsEditData = z.infer<typeof schema>;

export const BankDetailsEdit = ({ bankAccount, setStep }: BankDetailsEditProps) => {
  const bankAccountDetails = bankAccount?.auBankAccount;
  const { driver } = usePayoutHistoryContext();
  const [updateBankAccount, { isLoading }] = useUpdateBankAccountMutation();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty },
  } = useForm<BankDetailsEditData>({
    resolver: zodResolver(schema),
    defaultValues: {
      accountHolder: bankAccountDetails?.accountHolder,
      accountNumber: bankAccountDetails?.accountNumber,
      bsb: bankAccountDetails?.bsb,
    },
  });

  const onSubmit = handleSubmit((data) => {
    const updateInvoiceSettingsData = {
      id: driver.uuid,
      account_holder: data.accountHolder,
      account_number: data.accountNumber,
      bsb: data.bsb,
    };

    updateBankAccount(updateInvoiceSettingsData)
      .unwrap()
      .then(() => {
        addToast("success", "Successfully Updated Bank Details");
        setStep(undefined);
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  });


  return (
    <form onSubmit={onSubmit} className="relative">
      {isLoading && <Loading />}
      <div className="flex flex-col bg-neutral-surface-gray rounded-lg mt-3 p-5 gap-6">
        <div className="flex flex-col">
          <Typography variant="paragraph" className="text-neutral-dark-gray">Full Name</Typography>
          <TextInput
            placeholder="Enter Full Name"
            hasError={!!errors.accountHolder}
            {...register("accountHolder")}
          />
          <ErrorMessage errors={errors} name="accountHolder" />
        </div>
        <div className="flex flex-col">
          <Typography variant="paragraph" className="text-neutral-dark-gray">Account number</Typography>
          <TextInput
            placeholder="Enter Account Number"
            hasError={!!errors.accountNumber}
            {...register("accountNumber")}
          />
          <ErrorMessage errors={errors} name="accountNumber" />
        </div>
        <div className="flex flex-col">
          <Typography variant="paragraph" className="text-neutral-dark-gray">Bank State Branch</Typography>
          <TextInput
            placeholder="000 - 000"
            hasError={!!errors.bsb}
            {...register("bsb")}
          />
          <ErrorMessage errors={errors} name="bsb" />
        </div>
      </div>
      <div className="flex flex-col sm:flex-row justify-end mt-4 gap-3">
        <Button variant="secondary" size="sm" className="w-full sm:w-fit" onClick={() => setStep(undefined)}>Cancel</Button>
        <Button 
          type="submit" 
          disabled={isSubmitting || !isDirty} 
          variant="primary" 
          size="sm" 
          className="w-full order-first sm:order-last sm:w-fit"
        >
          Save Bank Details
        </Button>
      </div>
    </form>
  );
};