import camelcaseKeys from "camelcase-keys";
import {
  AddDriverDocumentRawResponse,
  AddDriverDocumentResponse,
  RawDriverProfile,
  GetDriverProfileResponse,
  GetFleetDriversRawResponse,
  GetFleetDriversResponse,
  GetFleetLeadersResponse,
  GetFleetOptionsRawResponse,
  GetFleetOptionsResponse,
  GetOffloadDriversRawResponse,
  GetOffloadDriversResponse,
  GetOffloadOptionsRawResponse,
  GetOffloadOptionsResponse,
  GetDriverJobHistoryRawResponse,
  GetDriverJobHistoryResponse,
  GetDriverIncidentsRawResponse,
  GetDriverIncidentsResponse,
  GetPayoutsRawResponse,
  GetPayoutsResponse,
  GetPayoutItemsRawResponse,
  GetPayoutItemsResponse,
} from "./types";

const toNameValuePair = (obj: Record<string, string>) =>
  Object.entries(obj)
    .map(([key, label]) => ({ name: label, value: key }))
    .sort((a, b) => a.name.toString().localeCompare(b.name));

export const transformFleetDrivers = (res: GetFleetDriversRawResponse) => {
  const {
    _embedded: { driver: drivers },
    page,
    page_count,
    page_size,
    total_items,
  } = res;

  const response: GetFleetDriversResponse = {
    data: {
      drivers: camelcaseKeys(drivers, {
        deep: true,
      }),
      page: {
        current: page,
        count: page_count,
        size: page_size,
        total: total_items,
      },
    },
  };

  return response;
};

export const transformFleetLeaders = (res: GetFleetDriversRawResponse) => {
  const {
    _embedded: { driver: drivers },
    page,
    page_count,
    page_size,
    total_items,
  } = res;

  const response: GetFleetLeadersResponse = {
    data: {
      items: camelcaseKeys(drivers, {
        deep: true,
      }),
      page: {
        current: page,
        count: page_count,
        size: page_size,
        total: total_items,
      },
    },
  };

  return response;
};

export const transformOffloadDrivers = (res: GetOffloadDriversRawResponse) => {
  const {
    _embedded: { driver: drivers },
    page,
    page_count,
    page_size,
    total_items,
  } = res;

  const response: GetOffloadDriversResponse = {
    data: {
      drivers: drivers.map((i) => {
        const { provider, block, metadata } = i;
        return {
          id: i.uuid,
          name: i.name,
          state: i.state[0],
          provider: {
            providerKey: provider.provider_key,
            id: provider.uuid,
            name: provider.name,
            phoneNumber: provider.phone_number,
            appDeepLink: provider.app_deep_link,
            assets: provider.assets,
          },
          avatar: i.avatar,
          block: {
            isActive: block.is_active,
            reason: block.reason,
          },
          tripCount: metadata.trip_count,
          rating: metadata.rating,
        };
      }),
      page: {
        current: page,
        count: page_count,
        size: page_size,
        total: total_items,
      },
    },
  };

  return response;
};

export const transformFleetOptions = (res: GetFleetOptionsRawResponse) => {
  const response: GetFleetOptionsResponse = {
    data: {
      options: {
        isFleetLeader: toNameValuePair(res.is_fleet_leader),
        state: toNameValuePair(res.state),
        accountStatus: toNameValuePair(res.account_status),
        tier: toNameValuePair(res.tier),
        network: toNameValuePair(res.network),
        vehicleBrand: toNameValuePair(res.vehicle_brand),
        vehicleColor: toNameValuePair(res.vehicle_color),
        vehicleType: toNameValuePair(res.vehicle_type),
        year: res.year,
      },
    },
  };

  return response;
};

export const transformOffloadOptions = (res: GetOffloadOptionsRawResponse) => {
  const response: GetOffloadOptionsResponse = {
    data: {
      options: {
        state: toNameValuePair(res.state),
        isBlocked: toNameValuePair(res.is_blocked),
        vehicleBrand: toNameValuePair(res.vehicle_brand),
        vehicleColor: toNameValuePair(res.vehicle_color),
        vehicleType: toNameValuePair(res.vehicle_type),
        year: res.year,
      },
    },
  };

  return response;
};

export const transformDriverProfile = (res: RawDriverProfile) => {
  const response: GetDriverProfileResponse = {
    data: camelcaseKeys(res, { deep: true }),
  };

  return response;
};

export const transformDriverDocument = (res: AddDriverDocumentRawResponse) => {
  const response: AddDriverDocumentResponse = {
    data: {
      uuid: res.uuid,
      created: "",
      filename: res.filename,
      name: res.name,
      type: res.document_type,
    },
  };

  return response;
};

export const transformGetDriverJobHistory = (res: GetDriverJobHistoryRawResponse) => {
  const {
    _embedded: { job },
    page,
    page_count,
    page_size,
    total_items,
  } = res;

  const response: GetDriverJobHistoryResponse = {
    jobs: camelcaseKeys(job, {
      deep: true,
    }),
    page: {
      current: page,
      count: page_count,
      size: page_size,
      total: total_items,
    },
  };

  return response;
};

export const transformGetDriverIncidents = (res: GetDriverIncidentsRawResponse) => {
  const {
    _embedded: { incident },
    page,
    page_count,
    page_size,
    total_items,
  } = res;

  const response: GetDriverIncidentsResponse = {
    incidents: camelcaseKeys(incident, {
      deep: true,
    }),
    page: {
      current: page,
      count: page_count,
      size: page_size,
      total: total_items,
    },
  };

  return response;
};

export const transformPayouts = (res: GetPayoutsRawResponse) => {
  const {
    _embedded: { payout: payouts },
    page,
    page_count,
    page_size,
    total_items,
  } = res;

  const response: GetPayoutsResponse = {
    data: {
      payouts: payouts.map(payout => ({
        id: payout.uuid,
        amount: payout.amount,
        currency: payout.currency,
        status: payout.status,
        statusReason: payout.status_reason,
        paidOn: payout.paid_on,
        updatedAt: payout.updated_at,
        items: [],
      })),
      page: {
        current: page,
        count: page_count,
        size: page_size,
        total: total_items,
      },
    },
  };

  return response;
};

export const transformPayoutItems = (res: GetPayoutItemsRawResponse) => {
  const { _embedded: { payout: payouts } } = res;

  const response: GetPayoutItemsResponse = {
    data: {
      items: payouts.map(payout => ({
        identifier: payout.identifier,
        amount: payout.amount,
        description: payout.description,
        earnDate: payout.earn_date,
      })),
    },
  };

  return response;
};