import { Skeleton, ErrorPanel } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useGetFleetDriversQuery } from "@/redux/apis/driver/driverApi";
import { useAppSelector } from "@/redux/hooks";
import { fleetDriversSelector } from "@/redux/slices/driver/selectors";
import { MyDriversTable } from "./MyDriversTable";
import { useGetFleetDriversParams } from "./hooks/useGetFleetDriversParams";

export const MyDriversPanel = () => {
  const { params } = useGetFleetDriversParams();
  const { isFetching, error } = useGetFleetDriversQuery(params, { refetchOnMountOrArgChange: true });
  const drivers = useAppSelector(fleetDriversSelector);

  if (error) return <ErrorPanel messages={getErrorMessages(error)} />;
  if (isFetching) return <Skeleton />;

  return (
    <>
      <MyDriversTable items={drivers} />
      <MyDriversTable.Pagination />
    </>
  );
};
