import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/react";
import { Alert, Button, Icon, IconButton, Loading, Typography } from "@/components/atoms";
import { addToast, clsx, downloadFile, formatCurrency } from "@/utils";
import { useOperator, useProfile } from "@/hooks";
import { Payout } from "@/redux/slices/driver/types";
import { format } from "date-fns";
import { PayoutItemsTable } from "../contents/PayoutItemsTable";
import { usePayoutHistoryContext } from "../context";
import { useLazyRequestPayoutDocumentQuery } from "@/redux/apis/document/documentApi";
import { useEffect, useState } from "react";

interface PayoutDisclosureProps extends React.HTMLAttributes<HTMLDivElement> {
  payout: Payout;
}

export const PayoutDisclosure = ({ payout, className }: PayoutDisclosureProps) => {
  const { driver, setStep, setSelectedPayout, isDisclosureOpen, setIsDisclosureOpen } = usePayoutHistoryContext();
  const [requestDocument, { data }] = useLazyRequestPayoutDocumentQuery();
  const [isLoading, setIsLoading] = useState(false);
  const [downloadAttempt, setDownloadAttempt] = useState(0);

  const { dateFormat } = useProfile();
  const { roles } = useOperator();
  const isRM = roles.includes("Fa_Access");

  useEffect(() => {
    if (data?.status === "PENDING" && downloadAttempt < 3) setTimeout(() => downloadDocument(), 2000);
    else if (data?.status !== "SUCCESS" && downloadAttempt >= 3) {
      addToast("danger", "Failed to download file.");
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadAttempt]);

  const downloadDocument = async () => {
    setIsLoading(true);
    await requestDocument({
      id: payout.id,
      driverId: driver.uuid,
    })
      .unwrap()
      .then((res) => {
        if (res.status === "SUCCESS") {
          downloadFile(res.filename, res.filename);
          setIsLoading(false);
        }
      });

    setDownloadAttempt(downloadAttempt + 1);
  };

  const renderStatus = (status: string) => {
    switch (status) {
      case "unpaid":
      case "dispersed":
        return (
          <div className="flex flex-row items-center gap-1.5">
            <span className="h-2 w-2 rounded-full bg-info" />
            <Typography variant="small" className="text-neutral-dark-gray">In Transit</Typography>
          </div>
        );
      case "paid":
        return (
          <div className="flex flex-row items-center gap-1.5">
            <span className="h-2 w-2 rounded-full bg-success" />
            <Typography variant="small" className="text-neutral-dark-gray">Paid</Typography>
          </div>
        );
      case "rejected":
      case "error":
        return (
          <div className="flex flex-row items-center gap-1.5">
            <span className="h-2 w-2 rounded-full bg-danger" />
            <Typography variant="small" className="text-neutral-dark-gray">Failed</Typography>
          </div>
        );
      default: return <Typography variant="small" className="text-neutral-dark-gray">-</Typography>;
    };
  };

  const onClickMarkAsFailed = () => {
    setSelectedPayout(payout);
    setStep("mark-as-failed");
  }

  const handleDisclosureButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const disclosureButtons = document.getElementsByClassName("payout-disclosure-button");
    let openedCount = 0;

    for (let i = 0; i < disclosureButtons.length; i++) {
      const disclosureButton = disclosureButtons.item(i) as HTMLButtonElement;
      if (disclosureButton.getAttribute("data-headlessui-state") === "open") openedCount++;
      if (disclosureButton && disclosureButton !== event.target && disclosureButton.getAttribute("data-headlessui-state") === "open") disclosureButton.click();
    }

    if (openedCount === 0) {
      if (!isDisclosureOpen) setIsDisclosureOpen(true);
      else setIsDisclosureOpen(false);
    } else setIsDisclosureOpen(true);
  };

  return (
    <Disclosure as="div" className={clsx("rounded-lg bg-white shadow-lg", className)}>
      {({ open }) => (
        <>
          <DisclosureButton 
            onClick={handleDisclosureButtonClick} 
            className={clsx(
              "payout-disclosure-button", 
              "flex flex-row items-center w-full py-3.5 px-5 outline-none", 
              { 
                "border border-primary border-b-0 rounded-t-lg": open,
                "opacity-70": !open && isDisclosureOpen, 
              },
            )}
            >
            <div className="flex flex-1 flex-row items-center">
              {open ? <Icon name="ArrowDown2" variant="Bold" /> : <Icon name="ArrowRight2" variant="Bold" />}
              <div className="flex flex-col ml-3.5">
                <Typography variant="action">{`${payout.paidOn ? format(new Date(payout.paidOn), dateFormat) : "-"}`}</Typography>
                {renderStatus(payout.status)}
              </div>
            </div>
            <div className="flex flex-row gap-12">
              <div className="flex flex-col items-end">
                <Typography variant="action">Payout to bank account</Typography>
                <Typography variant="small" className="text-neutral-dark-gray">Description</Typography>
              </div>
              <div className="flex flex-col items-end">
                <Typography variant="action">{payout.amount ? `${formatCurrency(payout.amount, 2, payout.currency)} ${payout.currency}` : "-"}</Typography>
                <Typography variant="small" className="text-neutral-dark-gray">Total Amount</Typography>
              </div>
            </div>
          </DisclosureButton>
          <DisclosurePanel 
            className={clsx(
              "flex flex-col gap-4 py-3.5 px-5", 
              {
                "border border-primary border-t-0 rounded-b-lg": open,
                "opacity-70": !open && isDisclosureOpen, 
              },
            )}
          >
            <div className="flex flex-row items-center justify-end gap-3">
              {isRM && payout.status === "dispersed" && <Button size="sm" variant="secondary" startIcon="Refresh2" className="!h-[34px]" onClick={onClickMarkAsFailed}>Mark as Failed</Button>}
              <div className="relative">
                {isLoading && <Loading />}
                <IconButton iconName="ImportCurve" size="sm" variant="secondary" className="text-info !w-[34px] !h-[34px]" onClick={downloadDocument} />
              </div>
            </div>
            <PayoutItemsTable payout={payout} />
            {(payout.status === "rejected" || payout.status === "error") && payout.statusReason && <Alert type="danger" message={payout.statusReason} />}
          </DisclosurePanel>
        </>
      )}
    </Disclosure>
  );
};
