import { DataTableColumnDef } from "@/components/molecules";
import { PayoutItem } from "@/redux/apis/driver/types";
import { formatCurrency } from "@/utils";
import { format } from "date-fns";
import { PayoutItemsTableData } from "./types";

export const generatePayoutItemsTableColumns = () => {
  const columns: DataTableColumnDef<PayoutItemsTableData>[] = [
    { accessorKey: "description", header: "Description", cell: (props) => props.row.original.description, enableSorting: false },
    { accessorKey: "jobDate", header: "Job Date (if applicable)", cell: (props) => props.row.original.jobDate, enableSorting: false },
    { accessorKey: "amount", header: "Amount", cell: (props) => props.row.original.amount, enableSorting: false },
  ];

  return columns;
};

export const generatePayoutItemsTableData = (items: PayoutItem[], dateFormat: string, currency: string) => {
  const data = items.map(
    (i) =>
      ({
        description: i.description ? i.description : "-",
        jobDate: i.earnDate ? format(new Date(i.earnDate), dateFormat) : "-",
        amount: `${formatCurrency(i.amount, 2, currency)}`,
      } as PayoutItemsTableData)
  );

  return data;
};