import { Button, Modal, Spinner, Typography } from "@/components/atoms";
import { useRemoveDriverIncidentMutation } from "@/redux/apis/driver/driverProfileApi";
import { DriverIncident } from "@/redux/apis/driver/types";
import { addToast, clsx } from "@/utils";
import { useAdminActionsContext } from "../context";

interface ConfirmRemoveIncidentModalProps extends React.HTMLAttributes<HTMLDivElement> {
  incident: DriverIncident;
  open: boolean;
  onClose: () => void;
}

export const ConfirmRemoveIncidentModal = ({ className, open, onClose, incident, ...props }: ConfirmRemoveIncidentModalProps) => {
  const { driver } = useAdminActionsContext();
  const [removeIncident, { isLoading }] = useRemoveDriverIncidentMutation();

  const handleRemove = () => {
    removeIncident({
      driverId: driver.uuid,
      incidentId: incident.uuid,
    })
      .unwrap()
      .then(() => {
        addToast("success", "Incident removed successfully");
        onClose();
      })
      .catch(() => {
        console.warn("Failed to remove incident");
      });
  };

  return (
    <Modal open={open} onClose={onClose} className="max-w-[514px] !p-5">
      <div className={clsx("relative", className)} {...props}>
        <div className="flex max-w-lg flex-col ">
          <div className="mt-3 flex flex-row items-start">
            <Typography variant="title" className="flex-1">
              Delete Incident Report {incident.jobId && `#${incident.jobId}`}
            </Typography>
          </div>
          <div className="mt-3 ">
            <Typography variant="paragraph">
              Are you sure you want to delete{" "}
              <span className="text-info">
                Incident Report {incident.jobId && `#${incident.jobId}`} (From {incident.createdBy.firstName} {incident.createdBy.lastName})
              </span>{" "}
              from {driver.personal.firstName} {driver.personal.lastName}&apos;s Incident Management?
            </Typography>
          </div>
        </div>
        <div className="mt-6 flex justify-end gap-4">
          <Button variant="secondary" onClick={onClose} size="md">
            Cancel
          </Button>
          <Button variant="primary" onClick={handleRemove} className="border-danger bg-danger hover:bg-danger-dark" size="md">
            {isLoading ? <Spinner /> : "Delete"}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
