import { useNavigate } from "react-router-dom";
import { Panel, Typography } from "@/components/atoms";
import { PageHeader } from "../../../../PageHeader/PageHeader";
import { DriverNavigation } from "../../common/DriverNavigation";
import { AdminNotes } from "./components/AdminNotes";
import { IncidentManagement } from "./components/IncidentManagement";
import { AdminActionsProvider, useAdminActionsContext } from "./context";

export const AdminActionsPanel = () => {
  const navigate = useNavigate();

  return (
    <AdminActionsProvider>
      <PageHeader.Actions onBack={() => navigate("../../")} />
      <Content />
    </AdminActionsProvider>
  );
};

const Content = () => {
  const { driver } = useAdminActionsContext();

  return (
    <div className="flex gap-3">
      <DriverNavigation driver={driver} />
      <Panel className="relative">
        <Typography variant="h3">
          Admin ({driver.personal.firstName} {driver.personal.lastName})
        </Typography>
        <AdminNotes />
        <IncidentManagement />
      </Panel>
    </div>
  );
};
