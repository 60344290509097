import { useMemo } from "react";
import { usePagination } from "@/hooks";
import { GetPayoutsParams } from "@/redux/apis/driver/types";

export const useGetPayoutsParams = (driverId: string) => {
  const { page, pageSize } = usePagination("payouts");

  const params: GetPayoutsParams = useMemo(
    () => ({
      page,
      page_size: pageSize,
      id: driverId,
    }),
    [page, pageSize, driverId]
  );

  return { params };
};
