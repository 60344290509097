import { Float, FloatProps } from "@headlessui-float/react";
import { Dialog, DialogPanel, Transition, TransitionChild } from "@headlessui/react";
import { useWindowSize } from "@react-hookz/web";
import { useCalendars, useDatePickerOffsetPropGetters } from "@rehookify/datepicker";
import { Fragment, useRef } from "react";
import { clsx } from "@/utils";
import { IconButton } from "../Button/IconButton";
import { Divider } from "../Divider/Divider";
import { DatePicker } from "./DatePicker";
import { Input } from "./Input";
import { Nav } from "./Nav";
import { DatePreset, DateRangePickerProvider, useDateRangePicker } from "./context";

export interface DateRangePickerProps extends React.HTMLAttributes<HTMLDivElement> {
  dates?: Date[];
  onDatesChange: (dates?: Date[]) => void;
  preset?: DatePreset;
  position?: FloatProps["placement"];
  size?: "sm" | "md" | "lg";
  placeholder?: string;
  dateFormat?: string;
  containerClassName?: string;
  autoPlacement?: boolean;
}

export const DateRangePicker = (props: DateRangePickerProps) => {
  const { dates, onDatesChange, preset, position } = props;
  return (
    <DateRangePickerProvider dates={dates} onDatesChange={onDatesChange} preset={preset}>
      <Component position={position} {...props} />
    </DateRangePickerProvider>
  );
};

const Component = ({
  className,
  containerClassName,
  position = "bottom-start",
  size,
  placeholder,
  dateFormat = "dd/MM/yyyy",
  autoPlacement = false,
}: DateRangePickerProps) => {
  const { open = false, setOpen, handleClose } = useDateRangePicker();
  const ref = useRef(null);
  const windowSize = useWindowSize();

  return (
    <Float
      offset={6}
      placement={position}
      autoPlacement={
        autoPlacement
          ? {
              allowedPlacements: ["top-start", "top-end", "bottom-start", "bottom-end"],
            }
          : undefined
      }
      dialog
    >
      <Float.Reference>
        <div className={clsx("inline-block w-full lg:max-w-[320px]", containerClassName)}>
          <Input
            onClick={() => setOpen(true)}
            size={size}
            placeholder={placeholder}
            className="placeholder:text-neutral-dark-gray"
            dateFormat={dateFormat}
          />
        </div>
      </Float.Reference>
      <Transition appear={true} show={open} as={Fragment}>
        <Dialog as="div" onClose={handleClose}>
          <TransitionChild
            as={Fragment}
            enter="duration-100 ease-out"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className={`fixed inset-0 z-40 ${windowSize.width >= 768 ? "bg-transparent" : "bg-black/20"} bg-opacity-50 `} />
          </TransitionChild>

          <div className="fixed inset-0 z-50">
            <Float.Content
              as={Fragment}
              transitionChild
              enter="duration-100 ease-out"
              enterFrom="opacity-0 scale-50"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-50"
              tailwindcssOriginClass
            >
              <DialogPanel
                className={clsx(
                  "flex transform select-none flex-col overflow-hidden rounded-xl bg-white shadow transition-[transform,opacity]  md:flex-row",
                  className
                )}
                ref={ref}
              >
                <Nav />
                <DatePickers />
              </DialogPanel>
            </Float.Content>
          </div>
        </Dialog>
      </Transition>
    </Float>
  );
};

Component.displayName = "DateRangePicker";

const DatePickers = () => {
  const { dpState } = useDateRangePicker();
  const { calendars } = useCalendars(dpState);
  const { subtractOffset, addOffset } = useDatePickerOffsetPropGetters(dpState);

  return (
    <div className="flex w-auto flex-1 flex-col overflow-auto border-neutral-mid-gray max-md:max-h-[375px] max-md:w-[375px] md:flex-row md:justify-center md:border-l ">
      <DatePicker
        prevButton={
          <IconButton
            iconName="ArrowLeft2"
            iconSize="sm"
            variant="tertiary"
            className="ml-2 h-6 w-8 p-0 max-md:hidden xl:h-8 xl:w-8 "
            {...subtractOffset({ months: 1 })}
          />
        }
        calendar={calendars[0]}
      />
      <div className="w-0 max-md:hidden xl:w-6">
        <Divider className="mt-[47px]" />
      </div>
      <DatePicker
        nextButton={
          <IconButton
            iconName="ArrowRight2"
            iconSize="sm"
            variant="tertiary"
            className="mr-2 h-6 w-8 p-0 max-md:hidden xl:h-8 xl:w-8"
            {...addOffset({ months: 1 })}
          />
        }
        calendar={calendars[1]}
      />
    </div>
  );
};
