import { GetBankAccountResponse, GetBankAccountRawResponse } from "./types";

export const transformBankAccount = (res: GetBankAccountRawResponse) => {
  const { au_bank_account } = res;

  return {
    data: {
      type: res.type,
      auBankAccount: {
        accountHolder: au_bank_account.account_holder,
        accountNumber: au_bank_account.account_number,
        bsb: au_bank_account.bsb,
      }
    },
  } as GetBankAccountResponse;
};
