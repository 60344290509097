import { Icon, Typography } from "@/components/atoms";
import { DriverDetail } from "../../../../common/DriverDetail";
import { useProfileContext } from "../../context";

export const ArchivedDriverStatus = () => {
  const { driver } = useProfileContext();
  const { status } = driver;

  const renderApplicationStatus = () => {
    const statusClassName = "flex flex-row items-center gap-1.5 capitalize";
    let displayStatus = null;

    switch (status) {
      case "Declined":
      case "removed":
      case "left":
      case "inactive":
      case "Permanently Terminated":
      case "Retired":
      case "suspended":
      case "Temporarily Suspended":
        displayStatus = (
          <div className={statusClassName}>
            <Icon name="Trash" size="sm" variant="Bold" className="text-danger" />
            <Typography variant="action">{status}</Typography>
          </div>
        );
        break;
      default:
        break;
    }

    return <DriverDetail label="Application" detail={displayStatus} className="flex-1" />;
  };

  return (
    <div className="relative mt-6 flex flex-col rounded-lg border border-neutral-gray bg-neutral-surface-gray p-5">
      <Typography variant="title">Driver Status</Typography>
      <div className="mt-6 flex flex-col sm:flex-row">{renderApplicationStatus()}</div>
    </div>
  );
};
