import { useWindowSize } from "@react-hookz/web";
import { saveAs } from "file-saver";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Button, DropdownMenu, Icon, IconButton, Loading, SearchInput, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { usePagination } from "@/hooks";
import { useLazyExportDriversQuery } from "@/redux/apis/reporting/reportingApi";
import { addToast, clsx } from "@/utils";
import { DriversFilters } from "../../../features/Filters/DriversFilters";
import { useGetFleetDriversParams } from "../hooks/useGetFleetDriversParams";

export const MyDriversActionMenu = () => {
  const { width } = useWindowSize();
  // const phpHostUrl = getPhpHostUrl();
  // const { id: operatorId } = useOperator();
  const [exportDrivers, { isFetching }] = useLazyExportDriversQuery();
  const { search, setSearch } = useGetFleetDriversParams();
  const [isSearchActive, setIsSearchActive] = useState(false);
  const { clearPagination } = usePagination("my-drivers");

  const handleSearch = (value?: string) => {
    if (!value) setIsSearchActive(false);

    clearPagination();
    setSearch(value);
  };

  const handleExportDrivers = (type: "full" | "active") => {
    exportDrivers({ report_type: type })
      .unwrap()
      .then((res) => saveAs(res.data.path, "drivers"))
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  };

  if (!isSearchActive && !search) {
    return (
      <div className="flex flex-1 items-center justify-end gap-1 xl:gap-2 ">
        <Button
          className="max-md:[&>span]:hidden"
          variant="tertiary"
          size="sm"
          startIcon="SearchNormal1"
          onClick={() => setIsSearchActive(true)}
        >
          Search
        </Button>
        <FilterButton />
        <DropdownMenu
          button={
            <div className="relative">
              {isFetching && <Loading />}
              {width >= 768 ? (
                <DropdownMenu.Button as={Button} startIcon="Add" isDropdown size="sm" className="w-full">
                  Actions
                </DropdownMenu.Button>
              ) : (
                <DropdownMenu.Button as={IconButton} iconName="options" isCustomIcon variant="primary" size="sm" className="fill-white" />
              )}
            </div>
          }
          isCustomButton
          position="bottom-start"
        >
          {/* {import.meta.env.DEV ? ( */}
          <Link to="../my-drivers/add">
            <DropdownMenu.Item className="w-[298px] text-primary-dark">
              <Icon name="Add" className="mr-2.5" size="sm" />
              <Typography>Add Driver</Typography>
            </DropdownMenu.Item>
          </Link>
          {/* //   <a href={`${phpHostUrl}/o/${operatorId}/member/driver/create`} target="_blank" rel="noreferrer">
          //     <DropdownMenu.Item className="w-[298px] text-primary-dark">
          //       <Icon name="Add" className="mr-2.5" size="sm" />
          //       <Typography>Add Driver</Typography>
          //     </DropdownMenu.Item>
          //   </a> */}

          {/* <DropdownMenu.Item onClick={() => null} className="text-primary-dark w-[298px]">
            <Icon name="excel" isCustom className="mr-2.5" size="sm" />
            <Typography>Download Template</Typography>
          </DropdownMenu.Item> */}
          {/* <DropdownMenu.Item onClick={() => null} className="text-primary-dark w-[298px]">
            <Icon name="Setting4" className="mr-2.5" size="sm" />
            <Typography>Driver Application Rules</Typography>
          </DropdownMenu.Item> */}
          {/* <DropdownMenu.Item className="flex flex-row text-primary-dark w-[298px]">
            <Link to="" target="_blank">
              <Icon name="Document" className="mr-2.5" size="sm" />
              <Typography>Driver T&C</Typography>
            </Link>
            <Icon name="ExportSquare" size="sm" />
          </DropdownMenu.Item> */}
          <DropdownMenu.Item onClick={() => handleExportDrivers("active")} className="w-[298px] text-primary-dark">
            <Icon name="Export" className="mr-2.5" size="sm" />
            <Typography>Export Drivers (Approved Drivers)</Typography>
          </DropdownMenu.Item>
          <DropdownMenu.Item onClick={() => handleExportDrivers("full")} className="w-[298px] text-primary-dark">
            <Icon name="Export" className="mr-2.5" size="sm" />
            <Typography>Export Drivers (Full)</Typography>
          </DropdownMenu.Item>
          {/* <DropdownMenu.Item onClick={() => null} className="text-primary-dark w-[298px]">
            <Icon name="Sms" className="mr-2.5" size="sm" />
            <Typography>Invite Driver</Typography>
          </DropdownMenu.Item> */}
          {/* <DropdownMenu.Item onClick={() => null} className="text-primary-dark w-[298px]">
            <Icon name="DocumentUpload" className="mr-2.5" size="sm" />
            <Typography>Upload Bulk Drivers</Typography>
          </DropdownMenu.Item> */}
        </DropdownMenu>
      </div>
    );
  }

  return (
    <div className="flex flex-1 items-center justify-end gap-2">
      <SearchInput
        search={search}
        onSearch={handleSearch}
        autoFocus
        placeholder="Search Drivers"
        className={clsx("w-full max-w-sm p-0 sm:w-fit [&>input]:transition-size", isSearchActive && "[&>input]:w-[280px]")}
        size="sm"
        onClear={() => setIsSearchActive(false)}
      />
    </div>
  );
};

const FilterButton = () => {
  const { width } = useWindowSize();

  return width >= 768 ? <DriversFilters.Button /> : <DriversFilters.DrawerButton />;
};
