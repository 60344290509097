import { Button, Icon, Modal, Typography } from "@/components/atoms";
import { useAssignDriverVehicleMutation } from "@/redux/apis/driver/driverProfileApi";
import { DriverProfile } from "@/redux/slices/driver/types";
import { Vehicle } from "@/redux/slices/vehicle/types";
import { addToast, getAssetUrl } from "@/utils";

interface SwitchAssignedVehicleModalProps {
  driverId: string;
  currentVehicle?: DriverProfile["vehicle"];
  newVehicle: Vehicle;
  open: boolean;
  onClose: () => void;
}

export const SwitchAssignedVehicleModal = ({ driverId, currentVehicle, newVehicle, open, onClose }: SwitchAssignedVehicleModalProps) => {
  const [assignDriverVehicle, { isLoading }] = useAssignDriverVehicleMutation();

  const handleClick = () => {
    assignDriverVehicle({
      driverId: driverId,
      vehicleId: newVehicle.uuid,
    })
      .unwrap()
      .then(() => {
        addToast("success", "Vehicle assigned successfully");
        onClose();
      });
  };

  if (!newVehicle) return null;

  return (
    <Modal open={open} onClose={onClose} className="w-[550px] !px-5 !py-8">
      <div className="sm:flex sm:items-start">
        <div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
          <div className="flex flex-row items-center">
            <Typography variant="title" className="flex-1">
              Switch Driver’s Assigned Vehicle
            </Typography>
            <Icon name="close" isCustom className="flex cursor-pointer justify-end" onClick={onClose} />
          </div>
        </div>
      </div>
      <Typography className="mt-5">
        Are you sure you want to change the assigned vehicle for this driver? This action will unassign the current vehicle and assign the
        new one.
      </Typography>

      <div className="mb-2 mt-6 flex items-center gap-2">
        {currentVehicle && (
          <>
            <div className="w-full rounded-lg bg-neutral-gray p-2.5">
              <img
                src={getAssetUrl(currentVehicle.model.avatar, {
                  width: 36,
                  height: 36,
                  fit: "contain",
                  background: "transparent",
                })}
                alt={currentVehicle.model.make}
                className="h-[18px] w-[18px] shrink-0"
              />
              <Typography>
                {`${currentVehicle.model.make} ${currentVehicle.model.model}`}
                {currentVehicle.year && <span> ({currentVehicle.year})</span>}
              </Typography>
              <Typography className="text-neutral-dark-gray">Current Assigned Vehicle</Typography>
            </div>
            <div className="flex items-center">
              <Icon name="ArrowSwapHorizontal" size="md" />
            </div>
          </>
        )}
        <div className="w-full rounded-lg bg-neutral-gray p-2.5">
          <img
            src={getAssetUrl(newVehicle.model.avatar, {
              width: 36,
              height: 36,
              fit: "contain",
              background: "transparent",
            })}
            alt={newVehicle.model.make}
            className="h-[18px] w-[18px] shrink-0"
          />
          <Typography>
            {`${newVehicle.model.make} ${newVehicle.model.model}`}
            {newVehicle.year && <span> ({newVehicle.year})</span>}
          </Typography>
          <Typography className="text-neutral-dark-gray">New Assigned Vehicle</Typography>
        </div>
      </div>

      <Button variant="primary" className="mt-5 w-full" size="lg" onClick={handleClick} disabled={isLoading}>
        Confirm
      </Button>
    </Modal>
  );
};
