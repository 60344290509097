import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithAuth } from "@/redux/baseQuery";
import { transformBankAccount } from "./helpers";
import { GetBankAccountResponse, UpdateBankAccountParams } from "./types";

export const bankAccountApi = createApi({
  reducerPath: "bankAccountApi",
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    getBankAccount: builder.query<GetBankAccountResponse, string>({
      query: (id) => `/v3/account-management/${id}/bank-account`,
      transformResponse: transformBankAccount,
      providesTags: ["BankAccount"],
    }),
    updateBankAccount: builder.mutation<void, UpdateBankAccountParams>({
      query: ({ id, ...body }) => ({
        url: `/v3/account-management/${id}/bank-account`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["BankAccount"],
    }),
  }),
  tagTypes: ["BankAccount"],
});

export const { useGetBankAccountQuery, useUpdateBankAccountMutation } = bankAccountApi;
