import { useMemo } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Panel, Typography } from "@/components/atoms";
import { NotFound } from "@/components/pages";
import { clsx } from "@/utils";
import { PageHeader } from "../../../../PageHeader/PageHeader";
import { ActiveStatuses, SuspendedStatuses } from "../../MyDrivers/types";
import { DriverNavigation } from "../../common/DriverNavigation";
import { useVehiclesContext, VehiclesProvider } from "./context";

export const VehiclesPanel = () => {
  const navigate = useNavigate();

  return (
    <VehiclesProvider>
      <PageHeader.Actions onBack={() => navigate("../../")} />
      <Content />
    </VehiclesProvider>
  );
};

const Content = () => {
  const { driver } = useVehiclesContext();

  const title = useMemo(() => {
    return `${driver.personal.firstName} ${driver.personal.lastName}'s Vehicles`;
  }, [driver]);

  if ([...ActiveStatuses, ...SuspendedStatuses].includes(driver.status))
    return (
      <div className="flex gap-3">
        <DriverNavigation driver={driver} />
        <Panel className="relative">
          <div className="mb-6 flex items-center justify-between gap-3">
            <Typography variant="h3">{title}</Typography>
          </div>
          <Navigation />
          <Outlet />
        </Panel>
      </div>
    );

  return <NotFound noHeader noBackground />;
};

const Navigation = () => {
  const { pathname } = useLocation();

  return (
    <div className="flex -translate-x-0.5 gap-3">
      <Link to="./assigned">
        <Typography
          className={clsx(
            "rounded-lg px-2.5 py-1 font-medium",
            pathname.includes("assigned") ? "bg-primary-light text-primary" : "text-neutral-black"
          )}
        >
          Assigned Vehicles
        </Typography>
      </Link>
      <Link to="./owned">
        <Typography
          className={clsx(
            "rounded-lg px-2.5 py-1 font-medium",
            pathname.includes("owned") ? "bg-primary-light text-primary" : "text-neutral-black"
          )}
        >
          Owned Vehicles
        </Typography>
      </Link>
    </div>
  );
};
