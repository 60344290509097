import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Button, ErrorMessage, IconButton, Loading, PhoneNumberInput, TextInput, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useUpdateDriverProfileMutation } from "@/redux/apis/driver/driverProfileApi";
import { addToast, clsx } from "@/utils";
import { DriverDetail } from "../../../../common/DriverDetail";
import { useProfileContext } from "../../context";
import { EditEmergencyContactFormData, editEmergencyContactFormSchema } from "../../form";

export const EmergencyContact = () => {
  const { driver, setActiveModal, activeSection, setActiveSection } = useProfileContext();
  const { emergencyContact } = driver;
  const [updateDriverProfile, { isLoading }] = useUpdateDriverProfileMutation();
  const {
    control,
    register,
    handleSubmit,
    formState: { isDirty, errors: formErrors },
  } = useForm<EditEmergencyContactFormData>({
    resolver: zodResolver(editEmergencyContactFormSchema),
    defaultValues: {
      contactName: emergencyContact.contactName,
      contactNumber: emergencyContact.contactNumber,
    },
  });

  const handleEdit = () => {
    if (activeSection !== undefined) setActiveModal("save-changes");
    else setActiveSection("emergency-contact");
  };

  const onSubmit = handleSubmit((data) => {
    if (editMode && isDirty) {
      const { contactName, contactNumber } = data;
      updateDriverProfile({
        id: driver.uuid,
        emergency_contact: {
          contact_name: contactName,
          contact_number: contactNumber,
        },
      })
        .unwrap()
        .then(() => {
          addToast("success", "Successfully updated emergency contact");
          setActiveSection(undefined);
        })
        .catch((e) => getErrorMessages(e).forEach((message) => addToast("danger", message)));
    }
  });

  const editMode = activeSection === "emergency-contact";

  return (
    <form onSubmit={onSubmit} className="relative mt-4 flex flex-col rounded-lg border border-neutral-gray bg-neutral-surface-gray p-5">
      {isLoading && <Loading />}
      <div className="flex flex-row items-center">
        <Typography variant="title" className="flex-1">
          Emergency Contact
        </Typography>
        {editMode ? (
          <div className="flex flex-row items-center gap-3">
            <Button size="sm" variant="secondary" onClick={() => setActiveSection(undefined)}>
              Cancel
            </Button>
            <Button size="sm" variant="primary" type="submit" disabled={!isDirty}>
              Save
            </Button>
          </div>
        ) : (
          <IconButton variant="tertiary" iconName="Edit2" size="sm" onClick={handleEdit} />
        )}
      </div>
      <div
        className={clsx(
          "mt-6 grid gap-6",
          editMode ? "grid-cols-1 md:grid-cols-2 2xl:grid-cols-3" : "grid-cols-1 md:grid-cols-3 2xl:grid-cols-5"
        )}
      >
        <DriverDetail
          editMode={editMode}
          label="Contact Name (Optional)"
          detail={
            editMode ? (
              <div className="flex flex-col gap-1">
                <TextInput hasError={!!formErrors.contactName} placeholder="Contact Name" {...register("contactName")} />
                <ErrorMessage errors={formErrors} name="contactName" />
              </div>
            ) : (
              emergencyContact.contactName
            )
          }
        />
        <DriverDetail
          editMode={editMode}
          label="Contact Number"
          detail={
            editMode ? (
              <div className="flex flex-col gap-1">
                <PhoneNumberInput control={control} name="contactNumber" hasError={!!formErrors.contactNumber} />
                <ErrorMessage errors={formErrors} name="contactNumber" />
              </div>
            ) : (
              emergencyContact.contactNumber
            )
          }
        />
      </div>
    </form>
  );
};
