import { createContext, PropsWithChildren, useContext } from "react";
import { useParams } from "react-router-dom";
import { Loading } from "@/components/atoms";
import { useOperator } from "@/hooks";
import { useGetDriverProfileQuery } from "@/redux/apis/driver/driverProfileApi";
import { DriverProfile } from "@/redux/slices/driver/types";

interface IJobHistoryContext {
  driver: DriverProfile;
  isFetching: boolean;
}

export const JobHistoryContext = createContext<IJobHistoryContext | null>(null);

export const JobHistoryProvider = ({ children }: PropsWithChildren) => {
  const { driverId } = useParams<{ driverId: string }>();
  const { id: operatorId } = useOperator();
  const { data: driver, isFetching } = useGetDriverProfileQuery({ id: driverId ?? "", operatorId }, { skip: !driverId });

  if (isFetching || !driver) return <Loading className="!bg-transparent" />;

  return <JobHistoryContext.Provider value={{ driver: driver.data, isFetching }}>{children}</JobHistoryContext.Provider>;
};

export const useJobHistoryContext = () => {
  const context = useContext(JobHistoryContext);
  if (!context) {
    throw new Error("useJobHistoryContext must be used within an JobHistoryProvider");
  }
  return context;
};
