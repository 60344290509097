import { Toggle } from "@/components/atoms";
import { renderNumberPlate } from "@/components/organisms/Vehicles/MyVehicles/helpers";
import { DriverProfile } from "@/redux/slices/driver/types";
import { Vehicle } from "@/redux/slices/vehicle/types";
import { getAssetUrl } from "@/utils";
import { AssignedVehiclesTableData } from "./types";

export const generateAssignedVehiclesData = (
  operatorId: string,
  driver: DriverProfile,
  vehicles: Vehicle[],
  onToggle: (vehicle: Vehicle, checked: boolean) => void
): AssignedVehiclesTableData[] => {
  const data = vehicles.map(
    (vehicle) =>
      ({
        make: (
          <div className="flex items-center gap-2">
            <div className="flex h-6 w-6 items-center justify-center rounded bg-neutral-gray">
              <img
                src={getAssetUrl(vehicle.model.avatar, {
                  width: 36,
                  height: 36,
                  fit: "contain",
                  background: "transparent",
                })}
                alt={vehicle.model.make}
                className="h-[18px] w-[18px] shrink-0"
              />
            </div>

            {vehicle.model.make}
          </div>
        ),
        model: vehicle.model.model,
        numberPlate: renderNumberPlate(vehicle, operatorId),
        owner: vehicle.owner.name,
        actions: renderActions(driver, vehicle, onToggle),
      } as AssignedVehiclesTableData)
  );

  return data;
};

const renderActions = (driver: DriverProfile, vehicle: Vehicle, onToggle: (vehicle: Vehicle, checked: boolean) => void) => {
  return (
    <span className="flex items-center justify-end gap-1.5">
      {driver.vehicle?.uuid === vehicle.uuid ? "Assigned" : "Assign Vehicle"}
      <Toggle
        size="xs"
        checked={driver.vehicle?.uuid === vehicle.uuid}
        onChange={(checked) => onToggle(vehicle, checked)}
        className="mr-1"
      />
    </span>
  );
};
