import { Panel } from "@/components/atoms";
import { DriverNavigation } from "../../../common/DriverNavigation";
import { useProfileContext } from "../context";
import { Documents } from "./fields/Documents";
import { AccountDetails } from "./sections/AccountDetails";
import { DriverDetails } from "./sections/DriverDetails";
import { DriverHeader } from "./sections/DriverHeader";
import { DriverStatus } from "./sections/DriverStatus";
import { EmergencyContact } from "./sections/EmergencyContact";

export const ApprovedDriverPanel = () => {
  const { driver } = useProfileContext();

  return (
    <div className="flex gap-3">
      <DriverNavigation driver={driver} />
      <Panel>
        <DriverHeader />
        <DriverStatus />
        <AccountDetails />
        <DriverDetails />
        <Documents />
        <EmergencyContact />
      </Panel>
    </div>
  );
};
