import { useForm } from "react-hook-form";
import { Button, TextArea, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useUpdateFleetStatusMutation } from "@/redux/apis/driver/driverApi";
import { addToast } from "@/utils";
import { ArchivedStatuses } from "../../../MyDrivers/types";
import { useAdminActionsContext } from "../context";

export const AdminNotes = () => {
  const { driver, isEditMode, setIsEditMode } = useAdminActionsContext();
  const [updateFleetDriver, { isLoading }] = useUpdateFleetStatusMutation();
  const { register, handleSubmit } = useForm<{ notes: string }>({
    defaultValues: {
      notes: "", //todo: when notes field is added on driver profile object
    },
  });

  const isArchived = ArchivedStatuses.includes(driver.status);

  const onSubmit = async (data: { notes: string }) => {
    updateFleetDriver({ id: driver.uuid, notes: data.notes })
      .unwrap()
      .then(() => {
        addToast("success", "Notes updated successfully");
        setIsEditMode(false);
      })
      .catch((e) => {
        getErrorMessages(e).forEach((m) => addToast("danger", m));
      });
  };

  if (isEditMode) {
    return (
      <form onSubmit={handleSubmit(onSubmit)} className="mt-4 space-y-4 rounded-lg bg-neutral-gray p-4">
        <Typography variant="paragraph">
          Notes about {driver.personal.firstName} {driver.personal.lastName}
        </Typography>
        <TextArea {...register("notes")} placeholder="Add notes" rows={4} maxLength={4096} />
        <div className="flex w-full items-center justify-end gap-3">
          <Button onClick={() => setIsEditMode(false)} variant="secondary" className="!bg-transparent" size="sm">
            Cancel
          </Button>
          <Button type="submit" disabled={isLoading} variant="primary" size="sm">
            Save
          </Button>
        </div>
      </form>
    );
  }

  return (
    <div className="mt-4 space-y-4 rounded-lg bg-neutral-gray p-4">
      <Typography variant="title">
        Notes about {driver.personal.firstName} {driver.personal.lastName}
      </Typography>
      <Typography>
        Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis.
        Suspendisse urna nibh viverra non semper suscipit posuere a pede.
      </Typography>
      {!isArchived && (
        <Button onClick={() => setIsEditMode(true)} startIcon="Edit2" variant="secondary" className="!bg-transparent" size="sm">
          Update
        </Button>
      )}
    </div>
  );
};
