import { createApi } from "@reduxjs/toolkit/dist/query/react";
import camelcaseKeys from "camelcase-keys";
import { baseQueryWithoutProvider } from "@/redux/baseQuery";
import { GetVehiclesResponse } from "@/redux/slices/vehicle/types";
import { createSearchParams } from "@/utils";
import { transformGetVehiclesResponse } from "../vehicle/helpers";
import { GetVehiclesParams } from "../vehicle/types";
import { transformDriverProfile, transformDriverDocument, transformGetDriverJobHistory, transformGetDriverIncidents } from "./helpers";
import {
  GetDriverProfileResponse,
  GetDriverProfileParams,
  UpdateDriverProfileResponse,
  UpdateDriverProfileParams,
  AddDriverDocumentResponse,
  AddDriverDocumentParams,
  RemoveDriverDocumentParams,
  GetDriverJobHistoryResponse,
  GetDriverJobHistoryParams,
  GetDriverIncidentsResponse,
  DriverIncidentDocument,
  DriverIncident,
  RawDriverIncident,
  AddDriverIncidentParam,
} from "./types";

export const driverProfileApi = createApi({
  reducerPath: "driverProfileApi",
  baseQuery: baseQueryWithoutProvider(),
  endpoints: (builder) => ({
    getDriverVehicles: builder.query<GetVehiclesResponse, GetVehiclesParams>({
      query: ({ owner_uuid, ...params }) => ({
        url: `vehicle/${owner_uuid}?${createSearchParams(params).toString()}`,
      }),
      transformResponse: transformGetVehiclesResponse,
      providesTags: (result) =>
        result ? [...result.vehicles.map(({ uuid }) => ({ type: "DriverVehicle" as const, uuid })), "DriverVehicle"] : ["DriverVehicle"],
    }),
    getDriverProfile: builder.query<GetDriverProfileResponse, GetDriverProfileParams>({
      query: ({ id, operatorId }) => `driver/${id}/${operatorId}/profile`,
      providesTags: ["DriverProfile"],
      transformResponse: transformDriverProfile,
    }),
    updateDriverProfile: builder.mutation<UpdateDriverProfileResponse, UpdateDriverProfileParams>({
      query: ({ id, ...body }) => ({
        url: `driver/${id}/profile`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["DriverProfile"],
    }),
    addDriverDocument: builder.mutation<AddDriverDocumentResponse, AddDriverDocumentParams>({
      query: ({ id, body }) => ({
        url: `driver/${id}/document`,
        method: "POST",
        body,
      }),
      transformResponse: transformDriverDocument,
    }),
    removeDriverDocument: builder.mutation<void, RemoveDriverDocumentParams>({
      query: ({ id, documentId }) => ({
        url: `driver/${id}/document/${documentId}`,
        method: "DELETE",
      }),
    }),
    assignDriverVehicle: builder.mutation<void, { driverId: string; vehicleId: string }>({
      query: ({ driverId, vehicleId }) => ({
        url: `driver/${driverId}/vehicle/${vehicleId}/change`,
        method: "POST",
      }),
      invalidatesTags: ["DriverProfile"],
    }),
    getDriverJobHistory: builder.query<GetDriverJobHistoryResponse, GetDriverJobHistoryParams>({
      query: ({ driverId, ...params }) => `driver/${driverId}/job?${createSearchParams(params).toString()}`,
      transformResponse: transformGetDriverJobHistory,
    }),
    getDriverIncidents: builder.query<GetDriverIncidentsResponse, { driverId: string }>({
      query: ({ driverId }) => `driver/${driverId}/incident`,
      transformResponse: transformGetDriverIncidents,
      providesTags: ["DriverIncidents"],
    }),
    addDriverIncident: builder.mutation<DriverIncident, AddDriverIncidentParam>({
      query: ({ driverId, ...body }) => ({
        url: `driver/${driverId}/incident`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["DriverIncidents"],
      transformResponse: (res: RawDriverIncident) => camelcaseKeys(res, { deep: true }),
    }),
    removeDriverIncident: builder.mutation<void, { driverId: string; incidentId: string }>({
      query: ({ driverId, incidentId }) => ({
        url: `driver/${driverId}/incident/${incidentId}`,
        method: "DELETE",
      }),
      invalidatesTags: (_, __, { incidentId }) => [{ type: "DriverIncidents", uuid: incidentId }],
    }),
    getDriverIncidentDocument: builder.query<DriverIncidentDocument, { driverId: string; documentId: string }>({
      query: ({ driverId, documentId }) => `driver/${driverId}/incident-document/${documentId}`,
    }),
    addDriverIncidentDocument: builder.mutation<DriverIncidentDocument, { driverId: string; body: FormData }>({
      query: ({ driverId, body }) => ({
        url: `driver/${driverId}/incident-document`,
        method: "POST",
        body,
      }),
      invalidatesTags: (_, __, { driverId }) => [{ type: "DriverIncidents", uuid: driverId }],
    }),
    removeDriverIncidentDocument: builder.mutation<void, { driverId: string; documentId: string }>({
      query: ({ driverId, documentId }) => ({
        url: `driver/${driverId}/incident-document/${documentId}`,
        method: "DELETE",
      }),
      invalidatesTags: (_, __, { driverId }) => [{ type: "DriverIncidents", uuid: driverId }],
    }),
  }),
  tagTypes: ["DriverProfile", "DriverVehicle", "DriverJobHistory", "DriverIncidents"],
});

export const {
  useGetDriverVehiclesQuery,
  useGetDriverProfileQuery,
  useUpdateDriverProfileMutation,
  useAddDriverDocumentMutation,
  useRemoveDriverDocumentMutation,
  useAssignDriverVehicleMutation,
  useGetDriverJobHistoryQuery,
  useGetDriverIncidentsQuery,
  useAddDriverIncidentMutation,
  useRemoveDriverIncidentMutation,
  useGetDriverIncidentDocumentQuery,
  useLazyGetDriverIncidentDocumentQuery,
  useAddDriverIncidentDocumentMutation,
  useRemoveDriverIncidentDocumentMutation,
} = driverProfileApi;
