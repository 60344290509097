import { ArchivedDriversPanel } from "@/components/organisms";
import { AdminActions } from "./subpages/AdminActions";
import { JobHistory } from "./subpages/JobHistory";
import { Profile } from "./subpages/Profile";

export const ArchivedDrivers = () => {
  return (
    <div className="mb-12 flex flex-1 flex-col py-2">
      <ArchivedDriversPanel />
    </div>
  );
};

ArchivedDrivers.Profile = Profile;
ArchivedDrivers.AdminActions = AdminActions;
ArchivedDrivers.JobHistory = JobHistory;
