import { createContext, PropsWithChildren, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { Loading } from "@/components/atoms";
import { useOperator } from "@/hooks";
import { useGetDriverProfileQuery } from "@/redux/apis/driver/driverProfileApi";
import { DriverProfile } from "@/redux/slices/driver/types";

type DriverModal =
  | "driver-left"
  | "remove-driver"
  | "change-password"
  | "approve-driver"
  | "save-changes"
  | "driver-status"
  | "suspend-driver";
type DriverSection = "account-details" | "driver-details" | "emergency-contact" | "driver-status";

interface IProfileContext {
  driver: DriverProfile;
  isFetching: boolean;
  activeModal?: DriverModal;
  setActiveModal: (modal?: DriverModal) => void;
  activeSection?: DriverSection;
  setActiveSection: (section?: DriverSection) => void;
}

export const ProfileContext = createContext<IProfileContext | null>(null);

export const ProfileProvider = ({ children }: PropsWithChildren) => {
  const { driverId } = useParams<{ driverId: string }>();
  const { id: operatorId } = useOperator();
  const { data: driver, isFetching } = useGetDriverProfileQuery({ id: driverId ?? "", operatorId }, { skip: !driverId });
  const [activeModal, setActiveModal] = useState<IProfileContext["activeModal"]>(undefined);
  const [activeSection, setActiveSection] = useState<IProfileContext["activeSection"]>(undefined);

  if (isFetching || !driver) return <Loading className="!bg-transparent" />;

  return (
    <ProfileContext.Provider value={{ driver: driver.data, isFetching, activeModal, setActiveModal, activeSection, setActiveSection }}>
      {children}
    </ProfileContext.Provider>
  );
};

export const useProfileContext = () => {
  const context = useContext(ProfileContext);
  if (!context) {
    throw new Error("useProfileContext must be used within an ManageDriverProvider");
  }
  return context;
};
