import { DataTableColumnDef } from "@/components/molecules";
import { AssignedVehiclesTableData } from "./types";

export const assignedVehicleColumns: DataTableColumnDef<AssignedVehiclesTableData>[] = [
  {
    header: "Make",
    accessorKey: "make",
    cell: (props) => props.row.original.make,
    size: 250,
    sortDescFirst: false,
  },
  {
    header: "Model",
    accessorKey: "model",
    size: 200,
    sortDescFirst: false,
  },
  {
    header: "Number Plate",
    accessorKey: "registration_number",
    cell: (props) => props.row.original.numberPlate,
    sortDescFirst: false,
  },
  {
    header: "Vehicle Owner",
    id: "owner",
    accessorKey: "owner",
    enableSorting: false,
  },
  {
    header: "",
    id: "actions",
    cell: (props) => props.row.original.actions,
    maxSize: 200,
  },
];
