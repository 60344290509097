import { Controller, useFormContext } from "react-hook-form";
import { Button, Divider, Loading, TimeFrameInput, Toggle, Typography } from "@/components/atoms";
import { OffloadingConfiguration } from "@/redux/slices/addOn/types";

interface OffloadingEditProps {
  onSubmit: () => void;
  onClose: () => void;
  isLoading?: boolean;
}

export const OffloadingEdit = ({ onSubmit, onClose, isLoading }: OffloadingEditProps) => {
  const {
    control,
    trigger,
    watch,
    register,
    formState: { isDirty, errors },
  } = useFormContext<OffloadingConfiguration>();
  const { enabledAutomaticDispatchPrivateNetwork, enabledAutomaticDispatchPublicNetwork } = watch();

  const renderButtons = () => (
    <div className="mt-8 flex w-full flex-row items-center justify-end gap-x-4">
      <Button variant="secondary" size="sm" onClick={onClose}>
        Cancel
      </Button>
      <Button variant="primary" size="sm" type="submit" disabled={!isDirty || isLoading}>
        Save Settings
      </Button>
    </div>
  );

  return (
    <form onSubmit={onSubmit}>
      <div className="pointer-events-auto relative my-4 flex w-full flex-col rounded-lg border border-primary bg-neutral-surface-gray p-5">
        {isLoading && <Loading className="rounded-lg" />}
        <div className="flex flex-row items-center">
          <div className="flex flex-1 flex-col">
            <Typography variant="action">Auto Dispatch to Private Network</Typography>
            <Typography variant="paragraph" className="text-neutral-dark-gray">
              Set the time before pickup
            </Typography>
          </div>
          <Controller
            name="enabledAutomaticDispatchPrivateNetwork"
            control={control}
            render={({ field }) => (
              <Toggle
                checked={field.value}
                onChange={(checked) => {
                  field.onChange(checked);
                  trigger();
                }}
              />
            )}
          />
        </div>
        <div className="flex flex-col">
          <div className="mt-5 flex flex-col lg:flex-row lg:items-center">
            <div className="flex flex-1 flex-row items-center gap-3">
              <Typography variant="action" className="text-neutral-dark-gray">
                Set when the system will start dispatching before pickup
              </Typography>
            </div>
            <TimeFrameInput
              className="w-48"
              min="0"
              max="60"
              timeName="dispatchAutoPrivateNetworkTime"
              timeFrameName="dispatchAutoPrivateNetworkTimeframe"
              register={register}
              options={["hour", "minute", "day"]}
              disabled={!enabledAutomaticDispatchPrivateNetwork}
            />
          </div>
          {errors.dispatchAutoPrivateNetworkTime?.message && (
            <Typography variant="paragraph" className="text-danger">
              {errors.dispatchAutoPrivateNetworkTime.message}
            </Typography>
          )}
        </div>
        <Divider className="my-6" />
        <div className="flex flex-row items-center">
          <div className="flex flex-1 flex-col">
            <Typography variant="action">Auto Dispatch to Public Network</Typography>
            <Typography variant="paragraph" className="text-neutral-dark-gray">
              Set the time before pickup
            </Typography>
          </div>
          <Controller
            name="enabledAutomaticDispatchPublicNetwork"
            control={control}
            render={({ field }) => (
              <Toggle
                checked={field.value}
                onChange={(checked) => {
                  field.onChange(checked);
                  trigger();
                }}
              />
            )}
          />
        </div>
        <div className="flex flex-col">
          <div className="mt-5 flex flex-col lg:flex-row lg:items-center">
            <div className="flex flex-1 flex-row items-center gap-3">
              <Typography variant="action" className="text-neutral-dark-gray">
                Set when the system will start dispatching before pickup
              </Typography>
            </div>
            <TimeFrameInput
              className="w-48"
              min="0"
              max="60"
              timeName="dispatchAutoPublicNetworkTime"
              timeFrameName="dispatchAutoPublicNetworkTimeframe"
              register={register}
              options={["hour", "minute", "day"]}
              disabled={!enabledAutomaticDispatchPublicNetwork}
            />
          </div>
          {errors.dispatchAutoPublicNetworkTime?.message && (
            <Typography variant="paragraph" className="text-danger">
              {errors.dispatchAutoPublicNetworkTime.message}
            </Typography>
          )}
        </div>
        {renderButtons()}
      </div>
    </form>
  );
};
