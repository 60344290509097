import { useMemo } from "react";
import { useOperator, usePagination, useSearch, useSorting } from "@/hooks";
import { GetFleetDriversParams } from "@/redux/apis/driver/types";
import { useDriversFilters } from "../../../features/Filters/useDriversFilters";
import { ActiveStatuses, PendingStatuses, SuspendedStatuses } from "../types";

export const useGetFleetDriversParams = () => {
  const { id: operatorId } = useOperator();
  const { page, pageSize } = usePagination("my-drivers");
  const [search, setSearch] = useSearch();
  const { sorting, setSorting, sortKey, direction } = useSorting();
  const { params: filterParams } = useDriversFilters();
  const { account_status, ...filterParamsRest } = filterParams;

  const params: GetFleetDriversParams = useMemo(
    () => ({
      page,
      page_size: pageSize,
      sort: sortKey,
      direction,
      search,
      provider_uuid: operatorId,
      account_status: account_status ? account_status : [...ActiveStatuses, ...PendingStatuses, ...SuspendedStatuses],
      ...filterParamsRest,
    }),
    [page, pageSize, sortKey, direction, search, operatorId, account_status, filterParamsRest]
  );

  return {
    params,
    sorting,
    setSorting,
    sortKey,
    direction,
    search,
    setSearch,
    operatorId,
    filters: filterParams,
  };
};
