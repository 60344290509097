import { Drawer, Icon, Loading, Modal, Typography } from "@/components/atoms";
import { useGetBankAccountQuery } from "@/redux/apis/bankAccount/bankAccountApi";
import { useWindowSize } from "@react-hookz/web";
import { useState } from "react";
import { BankDetailsEdit } from "../contents/BankDetailsEdit";
import { BankDetailsView } from "../contents/BankDetailsView";
import { usePayoutHistoryContext } from "../context";
import { BankDetailsStep } from "../types";

interface BankDetailsModalProps {
  open: boolean;
  onClose: () => void;
}

export const BankDetailsModal = ({ open, onClose }: BankDetailsModalProps) => {
  const { width } = useWindowSize();

  return (
    <>
      {width >= 768 ? (
        <Modal open={open} onClose={onClose} className="w-[550px] !px-5 !py-8">
          <Content onClose={onClose} />
        </Modal>
      ) : (
        <Drawer open={open} onOpenChange={(open) => !open && onClose()}>
          <></>
          <Content onClose={onClose} />
        </Drawer>
      )}
    </>
  );
};

const Content = ({ onClose }: { onClose: () => void; }) => {
  const { driver } = usePayoutHistoryContext();
  const { data, isFetching } = useGetBankAccountQuery(driver.uuid);
  const [step, setStep] = useState<BankDetailsStep>();
  const bankAccount = data?.data;

  return (
    <div className="relative mt-3 w-full sm:mt-0 sm:text-left">
      {isFetching && <Loading />}
      <div className="flex flex-row items-center">
        <Typography variant="title" className="flex-1">
          {`${driver.personal.firstName} ${driver.personal.lastName} Bank Details`}
        </Typography>
        <Icon name="close" isCustom className="flex cursor-pointer justify-end" onClick={onClose} />
      </div>
      {step === "edit" ? <BankDetailsEdit setStep={setStep} bankAccount={bankAccount} /> : <BankDetailsView setStep={setStep} bankAccount={bankAccount} />}
    </div>
  );
};