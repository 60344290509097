import { Button, Drawer, ErrorMessage, Icon, Loading, Modal, TextArea, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useFailPayoutMutation } from "@/redux/apis/driver/driverPayoutApi";
import { addToast } from "@/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { useWindowSize } from "@react-hookz/web";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { usePayoutHistoryContext } from "../context";

interface MarkAsFailedModalProps {
  open: boolean;
  onClose: () => void;
}

export const MarkAsFailedModal = ({ open, onClose }: MarkAsFailedModalProps) => {
  const { width } = useWindowSize();

  return (
    <>
      {width >= 768 ? (
        <Modal open={open} onClose={onClose} className="w-[550px] !px-5 !py-8">
          <Content onClose={onClose} />
        </Modal>
      ) : (
        <Drawer open={open} onOpenChange={(open) => !open && onClose()}>
          <></>
          <Content onClose={onClose} />
        </Drawer>
      )}
    </>
  );
};

const schema = z.object({
  reason: z.string().min(1, "Field is required and can't be empty.").max(255, "Maximum of 255 characters."),
});

type MarkAsFailedData = z.infer<typeof schema>;

const Content = ({ onClose }: { onClose: () => void; }) => {
  const { driver, selectedPayout } = usePayoutHistoryContext();
  const [failPayout, { isLoading }] = useFailPayoutMutation();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty },
  } = useForm<MarkAsFailedData>({
    resolver: zodResolver(schema),
  });

  const onSubmit = handleSubmit((data) => {
    if (selectedPayout) {
      const failPayoutData = {
        driverId: driver.uuid,
        payoutId: selectedPayout.id,
        reason: data.reason,
      };
  
      failPayout(failPayoutData)
        .unwrap()
        .then(() => {
          addToast("success", "Successfully Marked as Failed");
          onClose();
        })
        .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
    }
  });

  return (
    <form onSubmit={onSubmit} className="relative mt-3 w-full sm:mt-0 sm:text-left">
      {isLoading && <Loading />}
      <div className="flex flex-row items-center">
        <Typography variant="title" className="flex-1">Reason for Failed Payment</Typography>
        <Icon name="close" isCustom className="flex cursor-pointer justify-end" onClick={onClose} />
      </div>
      <TextArea className="mt-3" placeholder="Enter your reason" rows={4} maxLength={255} hideCharCount hasError={!!errors.reason} {...register("reason")} />
      <ErrorMessage errors={errors} name="reason" />
      <Typography variant="paragraph" className="text-neutral-dark-gray">This would be forwarded to the designated driver</Typography>
      <Button type="submit" variant="primary" size="lg" className="mt-8 w-full" disabled={isSubmitting || !isDirty} >Submit Response</Button>
    </form>
  );
};