import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithoutProvider } from "@/redux/baseQuery";
import { createSearchParams } from "@/utils";
import { transformPayouts, transformPayoutItems } from "./helpers";
import {
  GetPayoutsResponse,
  GetPayoutsParams,
  GetPayoutItemsResponse, 
  GetPayoutItemsParams,
  FailPayoutParams,
} from "./types";

export const driverPayoutApi = createApi({
  reducerPath: "driverPayoutApi",
  baseQuery: baseQueryWithoutProvider(),
  endpoints: (builder) => ({
    getPayouts: builder.query<GetPayoutsResponse, GetPayoutsParams>({
      query: ({ id, ...params }) => `driver/${id}/payout?${createSearchParams(params).toString()}`,
      transformResponse: transformPayouts,
      providesTags: ["Payouts"],
    }),
    getPayoutItems: builder.query<GetPayoutItemsResponse, GetPayoutItemsParams>({
      query: ({ driverId, payoutId }) => `driver/${driverId}/payout/${payoutId}/items?page_size=100`,
      transformResponse: transformPayoutItems,
    }),
    failPayout: builder.mutation<void, FailPayoutParams>({
      query: ({ driverId, payoutId, ...body }) => ({
        url: `driver/${driverId}/payout/${payoutId}/fail-payout`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Payouts"],
    }),
  }),
  tagTypes: ["Payouts"],
});

export const {
  useGetPayoutsQuery,
  useGetPayoutItemsQuery,
  useFailPayoutMutation,
} = driverPayoutApi;
