import { Link } from "react-router-dom";
import { DropdownMenu, Icon, Typography } from "@/components/atoms";
import { Vehicle } from "@/redux/slices/vehicle/types";
import { getAssetUrl } from "@/utils";
import { VehicleStatusBadge } from "./common/VehicleStatusBadge";
import { MyVehicleTableData, VehicleRegistrationStatus } from "./types";
import { isDocumentExpiring } from "./utils";

export const generateMyVehicleTableData = (
  operatorId: string,
  vehicles: Vehicle[],
  onArchiveClick: (vehicle: Vehicle) => void
): MyVehicleTableData[] => {
  const data = vehicles.map(
    (vehicle) =>
      ({
        numberPlate: renderNumberPlate(vehicle, operatorId),
        nickname: vehicle.identifier,
        make: (
          <div className="flex items-center gap-2">
            <div className="flex h-6 w-6 items-center justify-center rounded bg-neutral-gray">
              <img
                src={getAssetUrl(vehicle.model.avatar, {
                  width: 36,
                  height: 36,
                  fit: "contain",
                  background: "transparent",
                })}
                alt={vehicle.model.make}
                className="h-[18px] w-[18px] shrink-0"
              />
            </div>

            {vehicle.model.make}
          </div>
        ),
        model: vehicle.model.model,
        year: vehicle.year?.toString() ?? "-",
        color: (
          <div className="flex items-center gap-2 capitalize">
            {vehicle.color ? (
              <>
                {vehicle.color.toLowerCase().replace("_", " ")}
                {vehicle.color && (
                  <div
                    className="h-3 w-3 rounded-full border border-black"
                    style={{ backgroundColor: vehicle.color.toLowerCase().replace("_", "") }}
                  />
                )}
              </>
            ) : (
              "-"
            )}
          </div>
        ),
        driver: (
          <div className="flex items-center gap-2">
            {vehicle.currentDriver ? (
              <>
                {vehicle.currentDriver.avatar ? (
                  <img
                    src={getAssetUrl(vehicle.currentDriver.avatar, {
                      width: 48,
                      height: 48,
                      fit: "contain",
                    })}
                    className="h-6 w-6 rounded-full"
                    alt={vehicle.currentDriver?.name}
                  />
                ) : (
                  <Icon
                    name="Profile"
                    size="sm"
                    variant="Bold"
                    className="h-6 w-6 rounded-full bg-neutral-gray p-1 text-neutral-dark-gray"
                  />
                )}
                <Typography className="line-clamp-1">{vehicle.currentDriver ? `${vehicle.currentDriver.name}` : "-"}</Typography>
              </>
            ) : (
              <Typography className="line-clamp-1">-</Typography>
            )}
          </div>
        ),
        actions: renderActions(vehicle, operatorId, onArchiveClick),
      } as MyVehicleTableData)
  );

  return data;
};

export const renderNumberPlate = (vehicle: Vehicle, operatorId: string) => {
  const link = `/operator/${operatorId}/vehicles/my-vehicles/${vehicle.uuid}`;

  const StatusBadge = () => {
    const registrationStatus = getRegistrationStatus(vehicle);
    const insuranceStatus = getInsuranceStatus(vehicle);

    const getStatus = () => {
      if (registrationStatus === "expired" || insuranceStatus === "expired") {
        return "expired";
      }

      if (registrationStatus === "expiring" || insuranceStatus === "expiring") {
        return "expiring";
      }

      return insuranceStatus === "verified" ? registrationStatus : insuranceStatus;
    };

    const status = getStatus();

    switch (status) {
      case "missing-details":
      case "expiring":
      case "expired":
        return <VehicleStatusBadge status={status} vehicle={vehicle} size="sm" />;

      case "verified":
      default:
        return <VehicleStatusBadge vehicle={vehicle} size="sm" />;
    }
  };

  return (
    <Link to={link} className="flex items-center gap-1 hover:text-info">
      {vehicle.registrationNumber}
      <StatusBadge />
    </Link>
  );
};

const renderActions = (vehicle: Vehicle, operatorId: string, onArchiveClick: (client: Vehicle) => void) => {
  const link = `/operator/${operatorId}/vehicles/my-vehicles/${vehicle.uuid}`;

  return (
    <>
      <DropdownMenu button={<Icon name="options" isCustom size="lg" />} position="bottom-start" className="!flex max-w-[24px]">
        <Link to={link}>
          <DropdownMenu.Item>
            <Icon name="ArrowRight" className="mr-2.5 text-primary-dark" size="sm" />
            <Typography className="text-primary-dark">View Vehicle</Typography>
          </DropdownMenu.Item>
        </Link>
        {!vehicle.metadata.isArchived && (
          <DropdownMenu.Item onClick={() => onArchiveClick(vehicle)}>
            <Icon name="Trash" className="mr-2.5 text-danger" size="sm" />
            <Typography className="text-primary-dark">Archive Vehicle</Typography>
          </DropdownMenu.Item>
        )}
      </DropdownMenu>
    </>
  );
};

export const getRegistrationStatus = (vehicle: Vehicle) => {
  const expiryDate = vehicle.registration.expiryDate ? new Date(vehicle.registration.expiryDate) : null;

  if (vehicle.registration.status === "VERIFIED") {
    if (expiryDate) {
      if (isDocumentExpiring(expiryDate)) {
        return "expiring";
      } else if (expiryDate < new Date()) {
        return "expired";
      } else {
        return "verified";
      }
    }
  }

  if (vehicle.categories.length === 0 && vehicle.registration.status !== "VERIFIED") return "missing-details";
  if (!vehicle.registration.lastUpdated && vehicle.registration.status === "UNVERIFIED") return "unverified";

  return "unknown";
};

export const getInsuranceStatus = (vehicle: Vehicle): VehicleRegistrationStatus => {
  const insuranceDoc = vehicle.documents.find((doc) => doc.documentType === "INSURANCE");

  if (insuranceDoc) {
    const expiryDate = insuranceDoc.expiryDate ? new Date(insuranceDoc.expiryDate) : null;

    if (expiryDate) {
      if (isDocumentExpiring(expiryDate)) {
        return "expiring";
      } else if (expiryDate < new Date()) {
        return "expired";
      } else {
        return "verified";
      }
    }
  }

  return "unknown";
};

export const getAccreditationStatus = (vehicle: Vehicle): VehicleRegistrationStatus => {
  const accreditationDoc = vehicle.documents.find((doc) => doc.documentType === "ACCREDITATION");

  if (accreditationDoc) {
    const expiryDate = accreditationDoc.expiryDate ? new Date(accreditationDoc.expiryDate) : null;

    if (expiryDate) {
      if (isDocumentExpiring(expiryDate)) {
        return "expiring";
      } else if (expiryDate < new Date()) {
        return "expired";
      } else {
        return "verified";
      }
    }
  }

  return "verified";
};
