import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithoutProvider } from "@/redux/baseQuery";
import { RequestedDocument } from "@/redux/slices/documents/types";
import { transformInvoicePreview } from "./helpers";
import { GetInvoicePreviewResponse, GetInvoicePreviewParams } from "./types";

export const documentApi = createApi({
  reducerPath: "documentApi",
  baseQuery: baseQueryWithoutProvider("files/document"),
  endpoints: (builder) => ({
    getInvoicePreview: builder.query<GetInvoicePreviewResponse, GetInvoicePreviewParams>({
      query: (body) => ({
        url: "",
        method: "POST",
        body: {
          type: "invoice_preview",
          file_type: "pdf",
          ...body,
        },
      }),
      transformResponse: transformInvoicePreview,
    }),
    requestVehicleDocument: builder.query<RequestedDocument, { id: string; fileType: string; vehicleId: string }>({
      query: (params) => ({
        url: "",
        method: "POST",
        body: {
          uuid: params.id,
          type: "vehicle_document",
          owner_uuid: params.vehicleId,
          file_type: params.fileType,
        },
      }),
    }),
    requestDriverDocument: builder.query<RequestedDocument, { id: string; driverId: string }>({
      query: (params) => ({
        url: "",
        method: "POST",
        body: {
          uuid: params.id,
          type: "driver_document",
          owner_uuid: params.driverId,
        },
      }),
    }),
    requestPayoutDocument: builder.query<RequestedDocument, { id: string; driverId: string }>({
      query: (params) => ({
        url: "",
        method: "POST",
        body: {
          uuid: params.id,
          type: "driver_payout_document",
          owner_uuid: params.driverId,
        },
      }),
    }),
  }),
});

export const { useLazyGetInvoicePreviewQuery, useLazyRequestVehicleDocumentQuery, useLazyRequestDriverDocumentQuery, useLazyRequestPayoutDocumentQuery } = documentApi;
