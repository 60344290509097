import { Link } from "react-router-dom";
import { DropdownMenu, Icon, Typography } from "@/components/atoms";
import { FleetDriver } from "@/redux/slices/driver/types";
import { renderFleetAvatar, renderFleetStatus } from "../MyDrivers/MyDrivers/helpers";
import { ArchivedDriversTableData } from "./types";

export const generateArchivedDriversTableData = (items: FleetDriver[], onRetainClick: (driverId: string) => void) => {
  const data = items.map(
    (i) =>
      ({
        name: renderDriverName(i),
        email: i.email,
        mobile: i.phone,
        status: renderFleetStatus(i.status),
        reason: i.statusNote,
        action: renderArchivedDriverActions(i.uuid, onRetainClick),
      } as ArchivedDriversTableData)
  );

  return data;
};

const renderDriverName = (driver: FleetDriver) => {
  return (
    <Link to={driver.uuid}>
      <div className="flex flex-row items-center gap-2.5 hover:text-info">
        {renderFleetAvatar(driver.avatar, driver.name, driver.isFleetLeader)}
        <Typography variant="paragraph">{driver.name}</Typography>
      </div>
    </Link>
  );
};

const renderArchivedDriverActions = (driverId: string, onRetainClick: (driverId: string) => void) => {
  return (
    <DropdownMenu button={<Icon name="options" isCustom size="lg" />} position="bottom-start" className="!flex max-w-[24px]">
      <DropdownMenu.Item onClick={() => onRetainClick(driverId)}>
        <Icon name="Refresh2" size="sm" className="mr-2.5" />
        <Typography variant="paragraph">Retain</Typography>
      </DropdownMenu.Item>
      <Link to={driverId}>
        <DropdownMenu.Item>
          <Icon name="UserSquare" size="sm" className="mr-2.5" />
          <Typography variant="paragraph">View Driver</Typography>
        </DropdownMenu.Item>
      </Link>
    </DropdownMenu>
  );
};
