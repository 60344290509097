import { format, parseISO } from "date-fns";
import { Controller, useFormContext } from "react-hook-form";
import { DateInput, ErrorMessage, PhoneNumberInput, TextInput } from "@/components/atoms";
import { useProfile } from "@/hooks";
import { clsx } from "@/utils";
import { DriverDetail } from "../../../../common/DriverDetail";
import { useProfileContext } from "../../context";
import { EditDriverDetailsFormData } from "../../form";

interface PersonalDriverDetailsProps {
  editMode: boolean;
}

export const PersonalDriverDetails = ({ editMode }: PersonalDriverDetailsProps) => {
  const { dateFormat } = useProfile();
  const { driver } = useProfileContext();
  const { personal } = driver;
  const {
    control,
    register,
    formState: { errors: formErrors },
  } = useFormContext<EditDriverDetailsFormData>();

  return (
    <div
      className={clsx(
        "mt-6 grid gap-6",
        editMode ? "grid-cols-1 md:grid-cols-2 2xl:grid-cols-3" : "grid-cols-1 md:grid-cols-3 2xl:grid-cols-5"
      )}
    >
      <DriverDetail
        editMode={editMode}
        label="First Name"
        detail={
          editMode ? (
            <div className="flex flex-col gap-1">
              <TextInput placeholder="Enter First Name" maxLength={45} hasError={!!formErrors.firstName} {...register("firstName")} />
              <ErrorMessage errors={formErrors} name="firstName" />
            </div>
          ) : (
            personal.firstName
          )
        }
      />
      <DriverDetail
        editMode={editMode}
        label="Last Name"
        detail={
          editMode ? (
            <div className="flex flex-col gap-1">
              <TextInput placeholder="Enter Last Name" maxLength={45} hasError={!!formErrors.lastName} {...register("lastName")} />
              <ErrorMessage errors={formErrors} name="lastName" />
            </div>
          ) : (
            personal.lastName
          )
        }
      />
      <DriverDetail
        editMode={editMode}
        label="Preferred Name (Optional)"
        detail={
          editMode ? (
            <div className="flex flex-col gap-1">
              <TextInput
                placeholder="Enter Preferred Name"
                maxLength={45}
                hasError={!!formErrors.preferredName}
                {...register("preferredName")}
              />
              <ErrorMessage errors={formErrors} name="preferredName" />
            </div>
          ) : (
            personal.preferredName
          )
        }
      />
      <DriverDetail
        editMode={editMode}
        label="Contact Number"
        detail={
          editMode ? (
            <div className="flex flex-col gap-1">
              <PhoneNumberInput control={control} name="mobile" hasError={!!formErrors.mobile} />
              <ErrorMessage errors={formErrors} name="mobile" />
            </div>
          ) : (
            personal.mobile
          )
        }
      />
      <DriverDetail
        editMode={editMode}
        label="Date of Birth"
        detail={
          editMode ? (
            <div className="flex flex-col gap-1">
              <Controller
                name="dateOfBirth"
                control={control}
                render={({ field }) => (
                  <DateInput
                    onChange={(date) => field.onChange(date)}
                    selected={field.value}
                    placeholderText="-- / -- / ----"
                    hasError={!!formErrors.dateOfBirth}
                    maxDate={new Date()}
                  />
                )}
              />
              <ErrorMessage errors={formErrors} name="dateOfBirth" />
            </div>
          ) : personal.dateOfBirth ? (
            format(parseISO(personal.dateOfBirth), dateFormat)
          ) : (
            "-"
          )
        }
      />
      <DriverDetail
        className="md:col-span-3"
        editMode={editMode}
        label="Address"
        detail={
          editMode ? (
            <div className="flex flex-col gap-1">
              <TextInput placeholder="Enter Address Line" maxLength={255} hasError={!!formErrors.address} {...register("address")} />
              <ErrorMessage errors={formErrors} name="address" />
            </div>
          ) : (
            personal.address
          )
        }
      />
    </div>
  );
};
